import React, { forwardRef, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const MessageList = forwardRef(({ messages, isTyping }, ref) => {
  const [typedMessage, setTypedMessage] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const [typingIntervalId, setTypingIntervalId] = useState(null);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.role === "assistant") {
      setTypedMessage("");
      setTypingIndex(0);
      if (typingIntervalId) {
        clearInterval(typingIntervalId);
      }
      const intervalId = setInterval(() => {
        setTypingIndex((prevIndex) => {
          if (prevIndex < lastMessage.content[0].text.value.length) {
            const newTypedMessage = lastMessage.content[0].text.value.substring(0, prevIndex + 1);
            setTypedMessage(newTypedMessage);
            scrollToBottom();
            return prevIndex + 1;
          } else {
            clearInterval(intervalId);
            return prevIndex;
          }
        });
      }, 25); // Adjust the speed as needed
      setTypingIntervalId(intervalId);
    }
  }, [messages]);

  useEffect(() => {
    return () => {
      if (typingIntervalId) {
        clearInterval(typingIntervalId);
      }
    };
  }, [typingIntervalId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  messages = messages.sort((a, b) => a.created_at - b.created_at);
  return (
    <>
      {messages.length === 0 && (
        <div style={{ color: "gray", fontStyle: "italic" }}>
          No messages yet
        </div>
      )}
      {messages.map((msg, index) => (
        <div key={index} className={`message ${msg.role}`}>
          {msg.role === "assistant" && index === messages.length - 1 ? (
            <ReactMarkdown>{typedMessage}</ReactMarkdown>
          ) : msg.role === "lead-capture" ? (
            msg.content[0].component
          ) : (
            <ReactMarkdown>{msg.content[0].text.value}</ReactMarkdown>
          )}
        </div>
      ))}
      {isTyping && (
        <div className="typing-animation">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      )}
      <div ref={ref}/>
    </>
  );
});

export default MessageList;
