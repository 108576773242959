import React, { useState } from "react";
import { Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faDatabase,
  faEnvelope,
  faGlobe,
  faLink,
  faLock,
  faMagic,
  faPaintBrush,
  faUsers,
  faWandMagic,
} from "@fortawesome/free-solid-svg-icons";
import "./SettingsTab.css";
import ModelSubTab from "./ModelSubTab";
import ChatInterfaceSubTab from "./ChatInterfaceSubTab";
import SourcesTab from "../SourcesTab/SourcesTab";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useUser } from "../../../../UserContext";
import LeadsSubTab from "./LeadsSubTab";
import GeneralSubTab from "./GeneralSubTab";
import WebhookSubTab from "./WebhookSubTab";

const SettingsTab = ({ handleBack }) => {
  const [activeTab, setActiveTab] = useState("general");
  const { id: chatbotId } = useParams(); // Call useParams outside of the callback
  const [chatbotData, setChatbotData] = useState({});
  const { endpoint } = useUser();

  useEffect(() => {
    const fetchChatbotData = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/chatbots/${chatbotId}`
        );
        setChatbotData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchChatbotData();
  }, [chatbotId]);
  return (
    <div>
      <Row>
        <Col>
          <h3>Settings</h3>
        </Col>
      </Row>
      <br />
      <Row>
        {/* Settings Tabs Column */}
        <Col md="3" className="settings-column">
          <ListGroup>
            <ListGroup>
              <ListGroupItem
                action
                active={activeTab === "general"}
                onClick={() => setActiveTab("general")}
              >
                <FontAwesomeIcon icon={faCog} /> General
              </ListGroupItem>
              <ListGroupItem
                action
                active={activeTab === "model"}
                onClick={() => setActiveTab("model")}
              >
                <FontAwesomeIcon icon={faDatabase} /> Instructions & Model
              </ListGroupItem>
              <ListGroupItem
                action
                active={activeTab === "chat-interface"}
                onClick={() => setActiveTab("chat-interface")}
              >
                <FontAwesomeIcon icon={faWandMagic} /> Chat Interface
              </ListGroupItem>
              <ListGroupItem
                // action
                active={activeTab === "leads"}
                onClick={() => setActiveTab("leads")}
                // style={{ pointerEvents: "none", opacity: "0.5"}}
              >
                <FontAwesomeIcon icon={faUsers} /> Lead Capture Form
              </ListGroupItem>
              <ListGroupItem
                action
                active={activeTab === "webhooks"}
                onClick={() => setActiveTab("webhooks")}
              >
                <FontAwesomeIcon icon={faLink} /> Webhooks
              </ListGroupItem>
              <ListGroupItem
                // action
                active={activeTab === "security"}
                style={{ pointerEvents: "none", opacity: "0.5" }}
                // onClick={() => setActiveTab("security")}
              >
                <FontAwesomeIcon icon={faLock} /> Security (coming soon...)
              </ListGroupItem>
              <ListGroupItem
                // action
                active={activeTab === "notifications"}
                style={{ pointerEvents: "none", opacity: "0.5" }}
                // onClick={() => setActiveTab("notifications")}
              >
                <FontAwesomeIcon icon={faEnvelope} /> Notifications (coming
                soon...)
              </ListGroupItem>
              <ListGroupItem
                action
                active={activeTab === "domains"}
                style={{ pointerEvents: "none", opacity: "0.5" }}
                // onClick={() => setActiveTab("domains")}
              >
                <FontAwesomeIcon icon={faGlobe} /> Domains (coming soon...)
              </ListGroupItem>
            </ListGroup>
          </ListGroup>
        </Col>

        {/* Settings Content Column */}
        <Col md="9" className="settings-content">
          {activeTab === "general" && (
            <GeneralSubTab />
          )}
          {activeTab === "model" && <ModelSubTab />}
          {activeTab === "chat-interface" && <ChatInterfaceSubTab />}
          {activeTab === "leads" && <LeadsSubTab />}
          {activeTab === "webhooks" && <WebhookSubTab />}
          {/* Add content for other tabs */}
        </Col>
      </Row>
    </div>
  );
};

export default SettingsTab;
