import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { motion } from "framer-motion";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./HeroSection.css";
import heroImg from "../../assets/hero-laptop-phone.png";
const HeroSection = ({ loginWithRedirect }) => {
  const heroVariants = {
    hidden: { opacity: 0, x: -200 },
    visible: { opacity: 1, x: 0 },
  };

  const graphicVariants = {
    hidden: { opacity: 0, x: 200 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <div className="hero-section">
      <div className="login-container">
        <div style={{
          alignSelf: "center",
        }}>
          <h2>
            Build a <b>chatbot</b> in seconds.
          </h2>
          <p>No credit card required. No coding required.</p>
          <Button
            className=""
            onClick={() => loginWithRedirect()}
            style={{
              borderColor: "#0C6DFD",
              marginTop: "1rem",
            }}
          >
            Start Building{" "}
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ marginLeft: "5px", color: "#6666ff" }}
            />
          </Button>
        </div>
        <div>
          <img
            src={heroImg}
            alt="chatbot"
            style={{
              width: "500px",
              height: "500px",
              marginRight: "100px",
              padding: "0",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

// import React from "react";
// import { Container, Row, Col, Button } from "reactstrap";
// import { motion } from "framer-motion";
// import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import './HeroSection.css';
// const HeroSection = ({ loginWithRedirect }) => {
//   const heroVariants = {
//     hidden: { opacity: 0, x: -200 },
//     visible: { opacity: 1, x: 0 },
//   };

//   const graphicVariants = {
//     hidden: { opacity: 0, x: 200 },
//     visible: { opacity: 1, x: 0 },
//   };

//   return (
//     <div
//       className="hero-section"
//     >
//       <div
//         className="login-container"
//         style={{
//           background: "linear-gradient(to left, #33ccff 0%, #6666ff 100%)",
//           color: "white",
//           padding: "2rem",
//           borderRadius: "10px",
//           boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//         }}
//       >
//         <h2>
//           Build a <b>Chatbot</b> in seconds.
//         </h2>
//         <p>No coding required.</p>
//         <Button
//           className="signin-btn"
//           onClick={() => loginWithRedirect()}
//           style={{
//             borderColor: "#0C6DFD",
//             marginTop: "1rem",
//           }}
//         >
//           Start Building{" "}
//           <FontAwesomeIcon
//             icon={faArrowRight}
//             style={{ marginLeft: "5px", color: "#6666ff" }}
//           />
//         </Button>
//       </div>
//     </div>
//   );
// };

// export default HeroSection;
