import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h4>About Us</h4>
                    <p>The White Label is a chatbot platform that helps you build chatbots for your business without any coding.</p>
                </div>
                <div className="footer-section">
                    <h4>Contact Us</h4>
                    <p>Email: noel@companionyou.com</p>
                    {/* <p>Phone: +1 (123) 456-7890</p> */}
                </div>
                {/* <div className="footer-section">
                    <h4>Follow Us</h4>
                    <p>Facebook | Twitter | LinkedIn</p>
                </div> */}
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 TheWhiteLabelChatbot. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
