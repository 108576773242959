import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Button,
  CardBody,
  Card,
  CardTitle,
  CardText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faChartLine,
  faDatabase,
  faPlug,
  faCode,
  faShareAlt,
  faTrashAlt,
  faMessage,
  faRobot,
  faEye,
  faExternalLink,
  faCopy,
  faPlusCircle,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import wixLogo from "../../../assets/wix-logo.png";
import wixStep from "../../../assets/wix-steps.png";
import "./ChatbotManagement.css";
import { Link, useParams } from "react-router-dom";

import SettingsTab from "./SettingsTab/SettingsTab";
import SourcesTab from "./SourcesTab/SourcesTab";
import axios from "axios";
import ChatBot from "../../ChatBot/ChatBot";
import { useUser } from "../../../UserContext";
import DashboardTab from "./DashboardTab/DashboardTab";
import { ToastContainer, toast } from "react-toastify";
import WixInstructions from "../../../assets/wix-instructions.gif";
import "react-toastify/dist/ReactToastify.css";

const ChatbotManagement = ({ handleBack }) => {
  const [activeTab, setActiveTab] = useState("settings");
  const [chatbotDetails, setChatbotDetails] = useState({});
  const [chatbotActive, setChatbotActive] = useState(true);
  const { endpoint } = useUser();
  const { id: chatbotId } = useParams(); // Call useParams outside of the callback
  const navigate = useNavigate();

  // Fetch chatbot details from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/chatbots/${chatbotId}`
        );
        console.log("Chatbot details:", response.data);
        setChatbotActive(response.data.active);
        setChatbotDetails(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []); // Add chatbotId as a dependency if needed

  const deleteChatbot = async () => {
    if (window.confirm("Are you sure you want to delete this chatbot?")) {
      try {
        const response = await axios.delete(
          `${endpoint}/api/chatbots/${chatbotId}`
        );
        console.log(response.data);
        toast.success("Chatbot deleted");
        // Use navigate to go to the chatbot list page
        setTimeout(() => {
          navigate("/chatbot-list");
        }, 1000);
      } catch (err) {
        console.error(err);
        toast.error("Error deleting chatbot");
      }
    }
  };

  // Placeholder content based on the active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case "chatbot":
        return (
          <>
            <h3 className="tab-content-header">General Info</h3>
            <div className="general-info">
              {Object.entries(chatbotDetails).map(([key, value]) => (
                <div key={key} className="info-item">
                  <h6 className="info-title">
                    {key.replace(/([A-Z])/g, " $1")}
                  </h6>
                  <p className="info-value">{value}</p>
                </div>
              ))}
            </div>
          </>
        );
      case "settings":
        return <SettingsTab></SettingsTab>;
      case "dashboard":
        return <DashboardTab></DashboardTab>;
        return <></>;
      case "sources":
        return <SourcesTab></SourcesTab>;
      case "integrations":
        return <div>Integrations coming soon...</div>;
      case "try-it-out":
        return (
          <div
            style={{
              width: "450px",
              height: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              justifySelf: "center",
              margin: "auto",
            }}
          >
            <ChatBot></ChatBot>
          </div>
        );
      case "embed":
        const embedUrl = `https://thewhitelabelchatbot.com/chatbot/${chatbotDetails.id}`;
        const iframeCode = `<iframe
      src="${embedUrl}"
      title="Chatbot"
      width="100%"
      style="height: 100%; min-height: 700px"
      frameborder="0"
    ></iframe>`;
        const widgetEmbedCode = `
          <!-- Include the chatbot widget script -->
          <script>
          window.embeddedChatbotConfig = {
            chatbotId: "${chatbotDetails.id}",
            domain: "https://thewhitelabelchatbot.com",
            fullscreen: false
          };
        </script>
        <script src="https://thewhitelabelchatbot-widget.netlify.app//chatbot-widget.js" defer></script>
        `;
        const copyWidgetEmbedCode = () => {
          const tempInput = document.createElement("input");
          tempInput.value = widgetEmbedCode;
          document.body.appendChild(tempInput);
          tempInput.select(); // Select the text in the input
          document.execCommand("copy"); // Copy the selected text
          document.body.removeChild(tempInput); // Remove the temporary input element
          alert("Script code copied to clipboard!");
        };
        const copyEmbedCode = () => {
          const tempInput = document.createElement("input");
          tempInput.value = iframeCode;
          document.body.appendChild(tempInput);
          tempInput.select(); // Select the text in the input
          document.execCommand("copy"); // Copy the selected text
          document.body.removeChild(tempInput); // Remove the temporary input element
          alert("Embed Code copied to clipboard!");
        };

        return (
          <div style={{}}>
            <h4>Add Your Chatbot to Your Website</h4>
            <div className="embed-options">
              <div
                className="embed-option"
                style={{
                  width: "500px!important",
                }}
              >
                <h5>Iframe</h5>
                <p>Include the following iframe in any web page:</p>
                <pre className="embed-code">{iframeCode}</pre>
                <Button
                  style={{
                    background: "#6666FF",
                    border: "none",
                  }}
                  onClick={copyEmbedCode}
                >
                  <FontAwesomeIcon icon={faCopy} /> Copy Iframe
                </Button>
              </div>
              <hr></hr>
              <div className="embed-option">
                <h5>JavaScript Widget</h5>
                <p> Include the following script in your {"<body>"} tag:</p>
                <pre className="embed-code">{widgetEmbedCode}</pre>
                <Button
                  style={{
                    background: "#6666FF",
                    border: "none",
                  }}
                  onClick={copyWidgetEmbedCode}
                >
                  <FontAwesomeIcon icon={faCopy} /> Copy Script
                </Button>
              </div>{" "}
              <div
                className="embed-option"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // wrap on mobile
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    maxWidth: "500px",
                    marginRight: "250px",
                  }}
                >
                  <h5>
                    Add to{" "}
                    <img src={wixLogo} alt="Wix Logo" className="wix-logo" />
                  </h5>
                  <p>To embed your chatbot in a Wix website:</p>
                  <ol>
                    <li>
                      Go to <b>Settings</b> in your site's dashboard.
                    </li>
                    <li>
                      Click the <b>Custom Code</b> tab in the <b>Advanced</b>{" "}
                      section.
                    </li>
                    <li>
                      Choose the <b>Body - end</b> option under{" "}
                      <b>Place Code in.</b>
                    </li>
                    <li>
                      Click <b>+ Add Custom Code</b>
                    </li>
                    <li>Paste the code script in the text box.</li>
                    <li>
                      Select an option under Add Code to Pages:
                      <ul>
                        <li>
                          All pages: This adds the code to all of your site's
                          pages, including any new pages that you create in the
                          future. Choose whether to load the code only once per
                          visit, or on each page your visitor opens.
                        </li>
                        <li>
                          Choose specific pages: Use the drop-down menu to
                          select the relevant pages.
                        </li>
                      </ul>
                    </li>
                    <li>Click Apply.</li>
                  </ol>
                  <Button
                    style={{
                      background: "#6666FF",
                      border: "none",
                    }}
                    onClick={copyWidgetEmbedCode}
                  >
                    <FontAwesomeIcon icon={faCopy} /> Copy Script
                  </Button>
                </div>
                <img
                  src={WixInstructions}
                  style={{
                    width: "400px",
                    height: "400px",
                    borderRadius: "20px",
                  }}
                ></img>
              </div>
            </div>
          </div>
        );

      case "share":
        const chatbotUrl = `https://thewhitelabelchatbot.com/chatbot/${chatbotDetails.id}`;
        const copyToClipboard = () => {
          // Create a temporary input element to hold the URL
          const tempInput = document.createElement("input");
          tempInput.value = chatbotUrl;
          document.body.appendChild(tempInput);
          tempInput.select(); // Select the text in the input
          document.execCommand("copy"); // Copy the selected text
          document.body.removeChild(tempInput); // Remove the temporary input element
          toast.success("Chatbot URL copied to clipboard!");
        };

        return (
          <div>
            <h4>Share Your Chatbot</h4>
            <div className="share-link-container">
              <input
                type="text"
                className="share-link"
                value={chatbotUrl}
                readOnly
              />
              <Button
                color="primary"
                className="copy-link-btn"
                onClick={copyToClipboard}
              >
                <FontAwesomeIcon icon={faCopy} /> Copy Link
              </Button>
              <Button
                color="success"
                className="visit-btn"
                href={chatbotUrl}
                target="_blank"
              >
                <FontAwesomeIcon icon={faExternalLink} /> Visit
              </Button>
            </div>
          </div>
        );
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="directory-display">
            <span>
              / <Link to="/chatbot-list">Chatbots</Link> / {chatbotDetails.name}{" "}
            </span>
            <Link
              color="info"
              to={`/chatbot/${chatbotId}`}
              style={{
                fontSize: ".7em",
              }}
              className="preview-button"
            >
              <FontAwesomeIcon icon={faExternalLink} /> Visit Chatbot
            </Link>
          </div>{" "}
        </Col>
      </Row>
      <Row>
        <br></br>
      </Row>
      <Row>
        {!chatbotActive ? (
          <Col>
            <Card body>
              <CardTitle tag="h5">Chatbot Inactive</CardTitle>
              <CardText>
                Your chatbot is currently inactive. To activate it, you will
                need to upgrade your account. <br />
                <br />
                <Button
                  style={{
                    background: "#6666FF",
                    border: "none",
                  }}
                  onClick={() => {
                    navigate("/pricing");
                  }}
                >
                  Upgrade My Account
                </Button>
              </CardText>
            </Card>
          </Col>
        ) : (
          <Col>
            <h3 className="page-header">
              Manage Your Chatbot{" "}
              {/* <Link
                color="info"
                to={`/chatbot/${chatbotId}`}
                style={{
                  fontSize: ".3em",
                  alignSelf: "center",
                  border: "2px solid #6666FF",
                  background: "#6666FF",
                  color: "white",
                  padding: "5px",
                  height: "25px",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "15px",
                  borderRadius: "5px",
                }}
                className="preview-button"
              >
                <FontAwesomeIcon icon={faExternalLink} />{' '} View Chatbot
              </Link> */}
            </h3>
            <Nav tabs>
              {/* <NavItem>
              <NavLink
                href="#"
                active={activeTab === "chatbot"}
                onClick={() => setActiveTab("chatbot")}
              >
                General
              </NavLink>
            </NavItem> */}
              <NavItem>
                <NavLink
                  href="#"
                  active={activeTab === "settings"}
                  onClick={() => setActiveTab("settings")}
                >
                  Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  active={activeTab === "dashboard"}
                  onClick={() => setActiveTab("dashboard")}
                >
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  active={activeTab === "sources"}
                  onClick={() => setActiveTab("sources")}
                >
                  Data Sources
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  active={activeTab === "try-it-out"}
                  onClick={() => setActiveTab("try-it-out")}
                >
                  <FontAwesomeIcon
                    icon={faMessage}
                    style={{
                      color: "#6666FF",
                    }}
                  />{" "}
                  Try Chat
                </NavLink>
              </NavItem>
              {/* <NavItem>
              <NavLink
                href="#"
                active={activeTab === "integrations"}
                onClick={() => setActiveTab("integrations")}
                disabled
              >
                <FontAwesomeIcon icon={faPlug} /> Integrations
              </NavLink>
            </NavItem> */}
              <NavItem>
                <NavLink
                  href="#"
                  active={activeTab === "embed"}
                  onClick={() => setActiveTab("embed")}
                >
                  <FontAwesomeIcon icon={faCode} /> Embed on Site
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  active={activeTab === "share"}
                  onClick={() => setActiveTab("share")}
                >
                  <FontAwesomeIcon icon={faShareAlt} /> Share
                </NavLink>
              </NavItem>
              <NavItem>
                <Button color="danger" onClick={deleteChatbot}>
                  <FontAwesomeIcon icon={faTrashAlt} /> Delete
                </Button>
              </NavItem>
            </Nav>
            <div className="tab-content">{renderTabContent()}</div>
          </Col>
        )}
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default ChatbotManagement;
