import React from "react";
import "./FeatureSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faPaintBrush,
  faShieldAlt,
  faSyncAlt,
  faPlug,
  faBrain,
  faGlobe,
  faUserShield,
  faEarth,
  faCode,
} from "@fortawesome/free-solid-svg-icons";

const featuresData = [
  {
    name: "Multiple Data Sources",
    description: "Import data from multiple sources to train your chatbot.",
    icon: faDatabase,
  },
  {
    name: "Customizations",
    description:
      "Customize your chatbot’s look and feel to match your brand’s style and website design.",
    icon: faPaintBrush,
  },
  {
    name: "Whitelabel",
    description: "Remove Chatbase branding and use a custom domain.",
    icon: faUserShield,
  },
  {
    name: "Privacy & Security",
    description:
      "Your data is hosted on secure servers with robust encryption and access control.",
    icon: faShieldAlt,
  },
  {
    name: "Auto-Retrain",
    description:
      "Set your chatbot to retrain automatically and always be synced with your data.",
    icon: faSyncAlt,
  },
  {
    name: "Integrations",
    description:
      "Connect your chatbot to your favorite tools like Slack, WhatsApp Zapier, and more.",
    icon: faPlug,
  },
  {
    name: "Powerful AI Models",
    description:
      "Choose from a variety of AI models, including GPT-3.5-turbo and GPT-4.",
    icon: faBrain,
  },
  {
    name: "80+ Languages",
    description:
      "Reach your customers in their native language even if your data is in a different language.",
    icon: faGlobe,
  },
  {
    name: "Webhooks",
    description:
      "Connect your chatbot to your backend systems and databases with webhooks.",
    icon: faEarth,
  },
  {
    name: "Lead Capture",
    description:
      "Capture leads and contacts from your chatbot and integrate them with your CRM or email marketing tool.",
    icon: faDatabase,
  },
  {
    name: "No-Code",
    description: "Create a chatbot without writing a single line of code.",
    icon: faCode,
  },
  {
    name: "Analytics and Insights",
    description:
      "Get insights into your chatbot's performance and user interactions with our analytics dashboard.",
    icon: faDatabase,
  },
];

const FeatureSection = () => {
  return (
    <div className="feature-section">
      {featuresData.map((feature, index) => (
        <div key={index} className="feature">
          <FontAwesomeIcon icon={feature.icon} className="feature-icon" />
          <div className="feature-text">
            <h5 className="feature-name">{feature.name}</h5>
            <p className="feature-description">{feature.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureSection;
