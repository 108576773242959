import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagic,
  faMagicWandSparkles,
  faSave,
  faWandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useParams } from "react-router";
import { useUser } from "../../../../UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModelSubTab = () => {
  const [instructions, setInstructions] = useState("");
  const [model, setModel] = useState("gpt-3.5-turbo");
  const [temperature, setTemperature] = useState(0);
  const [isRewriting, setIsRewriting] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const { endpoint } = useUser();
  let { id: chatbotId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/chatbots/${chatbotId}`
        );
        setInstructions(response.data.instructions);
        setModel(response.data.model);
        setTemperature(response.data.temperature);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [chatbotId]);

  const requestRewrite = async () => {
    setIsRewriting(true);
    try {
      const response = await axios.post(
        `${endpoint}/api/rewrite/instructions`,
        {
          content: instructions,
          contentType: "instructions",
        }
      );
      console.log("Instructions updated:", response.data);
      setInstructions(response.data);
      setIsRewriting(false);
      setIsSaveEnabled(true);
      toast.success("Instructions rewrite Successful");
    } catch (error) {
      console.error("Error updating instructions:", error);
      setIsRewriting(false);
      toast.error("Error rewriting instructions");
    }
  };

  const handleInstructionsChange = (event) => {
    setInstructions(event.target.value);
    setIsSaveEnabled(true);
  };

  const saveChanges = async () => {
    setIsSaveEnabled(false);
    try {
      console.log("saving instructions:", instructions);
      const response = await axios.put(
        `${endpoint}/api/chatbots/${chatbotId}/instructions`,
        {
          instructions,
        }
      );
      console.log("Instructions updated:", response.data);
      toast.success("Instructions updated");
    } catch (error) {
      console.error("Error updating instructions:", error);
      toast.error("Error updating instructions");
    }
  };

  const handleModelChange = (event) => {
    setModel(event.target.value);
    setIsSaveEnabled(true);
  };

  const handleTemperatureChange = (event) => {
    setTemperature(event.target.value);
    setIsSaveEnabled(true);
  };

  return (
    <div>
      <h4>Model</h4>
      <FormGroup>
        <Label for="instructions">Instructions</Label>
        <Input
          type="textarea"
          id="instructions"
          value={instructions}
          onChange={handleInstructionsChange}
        />
        <Button
          style={{
            marginTop: "10px",
            background: "#3333CC",
            border: "none",
          }}
          disabled={isRewriting}
          onClick={() => {
            requestRewrite();
            // setInstructions("Rewriting with A.I");
            // setIsSaveEnabled(true);
          }}
        >
          {isRewriting && <FontAwesomeIcon icon={faWandSparkles} spin /> }
          {!isRewriting && (
            <>
              <FontAwesomeIcon icon={faMagicWandSparkles} /> Rewrite for me{" "}
            </>
          )}
        </Button>
      </FormGroup>

      <FormGroup>
        <Label for="model">Model</Label>
        <Input
          type="select"
          id="model"
          value={model}
          disabled
          onChange={handleModelChange}
        >
          <option value="gpt-4">gpt-4-turbo-preview</option>
          <option value="gpt-4-turbo" disabled>
            gpt-3.5-turbo
          </option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="temperature">Temperature</Label>
        <Input
          type="range"
          id="temperature"
          value={temperature}
          onChange={handleTemperatureChange}
          min="0"
          max="1"
          step="0.1"
          disabled
        />
      </FormGroup>
      <Button color="primary" onClick={saveChanges} disabled={!isSaveEnabled}>
        <FontAwesomeIcon icon={faSave} /> Save
      </Button>
      <ToastContainer />
    </div>
  );
};

export default ModelSubTab;
