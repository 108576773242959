import React, { useState } from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import "./AccordianSection.css";
import SectionLandingPageImg from "../../assets/section-landing-page.gif";
import SectionTraining from "../../assets/section-training.gif";
import SectionLooks from "../../assets/section-looks.gif";
import SectionEmbed from "../../assets/section-embed.gif";
import SectionAnalytics from "../../assets/section-analytics.gif";
const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey);
  return (
    <button type="button" onClick={decoratedOnClick}>
      {children}
    </button>
  );
};

const SectionComponent = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [imageSrc, setImageSrc] = useState(SectionLandingPageImg);

  const accordionItems = [
    {
      key: "1",
      title: "Create a Chatbot",
      content:
        "Create a chatbot to help your customers. It takes less than 5 minutes.",
      image: SectionLandingPageImg,
    },
    {
      key: "2",
      title: "Train it with Your Data",
      content:
        "Train your chatbot with your own data. Upload files, text or website URLs.",
      image: SectionTraining,
    },
    {
      key: "3",
      title: "Customize the Look",
      content:
        "Customize your chatbot to match your brand. Change colors, fonts and more.",
      image: SectionLooks,
    },
    {
      key: "4",
      title: "Embed on Your Website",
      content: "Embed your chatbot on your website. It’s easy!",
      image: SectionEmbed,
    },
    {
      key: "5",
      title: "Analyze Your Chatbot",
      content:
        "Analyze your chatbot performance and improve it with our analytics.",
      image: SectionAnalytics,
    },
  ];

  const handleAccordionClick = (key, image) => {
    console.log("accordian click");
    console.log("key", key);
    console.log("image", image);
    setActiveKey(key);
    setImageSrc(image);
  };

  const animationProps = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(-20px)" },
    config: { tension: 200, friction: 12 },
  });

  return (
    <div className="section-component">
      <div className="accordion-container">
        <h3>How It Works</h3>
        <p>Create a chatbot, train it and embed it on your website.</p>
        <br />
        <Accordion defaultActiveKey="1">
          {accordionItems.map((item) => (
            <Card key={item.key}>
              <Card.Header onClick={
                () => handleAccordionClick(item.key, item.image)
              }>
                <CustomToggle
                  eventKey={item.key}
                  //   onClick={() => handleAccordionClick(item.key, item.image)}
                >
                  <span
                    style={{
                      fontSize: ".8rem",
                      color: "grey",
                    }}
                    onClick={() => handleAccordionClick(item.key, item.image)}
                  >
                    {item.key}
                  </span>{" "}
                  {item.title}
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={item.key}>
                <Card.Body>{item.content}</Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </div>
      <div className="image-container">
        <animated.img
          style={animationProps}
          src={imageSrc}
          alt="Accordion Related"

        />
      </div>
    </div>
  );
};

export default SectionComponent;
