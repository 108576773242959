import React from "react";
import "./CardSection.css";

import AccurateResponsesImg from "../../assets/cards-section/cards-accurate-responses.png";
import AnalyticsImg from "../../assets/cards-section/cards-analytics.png";
import LeadCaptureImg from "../../assets/cards-section/cards-lead-capture.png";

const cardsData = [
  {
    title: "Trustworthy, Accurate Answers",
    description:
      'With features like "Revise answers" and "Confidence score," you can be sure your chatbot is giving the right answers.',
    imageUrl: AccurateResponsesImg,
  },
  {
    title: "Capture Leads and Contacts",
    description:
      "Capture leads and contacts from your chatbot and integrate them with your CRM or email marketing tool.",
    imageUrl: LeadCaptureImg,
  },
  {
    title: "Analytics and Insights",
    description:
      "Get insights into your chatbot's performance and user interactions with our analytics dashboard.",
    imageUrl: AnalyticsImg,
  },
];

const CardSection = () => {
  return (
    <>
      <div style={{
        width: '80%',
        margin:'auto',
        textAlign: 'left',
        marginTop: '150px',
        paddingLeft: 20
      }}>
        <h3>Unleash the Power of Chatbots</h3>
        <p> Build a chatbot that understands your customers and helps them get what they need. </p>
        <br />{" "}
      </div>
      <div className="card-section grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className="group card relative flex flex-col overflow-hidden rounded-md border bg-white transition-all duration-500 ease-in-out hover:shadow-md"
          >
            <div className="relative bg-zinc-200 transition-all duration-500 ease-in-out">
              <img
                alt={card.title}
                loading="lazy"
                className="bottom-0 object-contain object-center px-4 pt-8"
                style={{ color: "transparent" }}
                src={card.imageUrl}
              />
            </div>
            <div className="z-10 flex flex-col items-start gap-1 bg-white p-4 lg:h-[12.5rem] xl:h-[10rem]">
              <h5 className="text-xl font-medium">{card.title}</h5>
              <p className="text-md font-normal text-zinc-700">
                {card.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CardSection;
