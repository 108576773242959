import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import TheWhiteLabelChatbotLogo from "../../assets/logo-black.png";

const InputBar = ({ onSend, isTyping, watermark }) => {
  const [messageText, setMessageText] = useState("");

  const handleSend = () => {
    if (messageText.trim() === "") {
      toast.error("Please enter a message");
      return;
    }
    onSend(messageText);
    setMessageText(""); // Clear the input after sending
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (messageText.trim() !== "") {
        handleSend();
      }else{
        toast.error("Please enter a message");
      }
    }
  };

  return (
    <div>
      <input
        type="text"
        value={messageText}
        placeholder="Type a message..."
        disabled={isTyping}
        onChange={(e) => setMessageText(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      {isTyping && <Button disabled>...</Button>}
      {!isTyping && (
        <button onClick={handleSend} style={{
          backgroundColor: "#017BFE"
        }}>
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      )}
      {watermark && (
        <div
          style={{
            fontSize: "0.8em",
            marginTop: "0.5em",
          }}
        >
          Powered by
          <img
            src={TheWhiteLabelChatbotLogo}
            style={{
              width: "100px",
              height: "auto",
              marginTop: "0.5em",
              opacity: 0.8,
              cursor: "pointer",
            }}
            onClick={() => window.open("https://thewhitelabelchatbot.com/")}
          />
        </div>
        // <div
        //   style={{
        //     fontSize: "0.8em",
        //     marginTop: "0.5em",
        //   }}
        // >
        //   Powered by{" "}
        //   <a
        //     href="https://thewhitelabelchatbot.com/"
        //     style={{
        //       color: "black",
        //       textDecoration: "none",
        //       fontWeight: "bold",
        //     }}
        //   >
        //     <b>The White Label Chatbot</b>
        //   </a>
        // </div>
      )}
    </div>
  );
};

export default InputBar;
