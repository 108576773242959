import React, { useState } from "react";
import "./FormCapture.css";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";

const FormCapture = () => {
  const [inputValue, setInputValue] = useState("");
  const { loginWithRedirect } = useAuth0();

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`Submitted: ${inputValue}`);
    // You can add your submission logic here
  };

  return (
    <div className="FormCaptureContainer">
      <h3>Get Started for <b>Free</b></h3>
      <p>Sign up now and start building your chatbot today.</p>
      <div className="form-capture-container">
        <Button
          onClick={() => {
            loginWithRedirect();
          }}
          className="form-capture-button"
        >
          Start Building!{
            ' '
          }
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
        {/* <form onSubmit={handleSubmit} className="form-capture">
          <input
            type="text"
            placeholder="Email or Phone Number"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="form-capture-input"
          />
          <button type="submit" className="form-capture-button">
            Submit
          </button>
        </form> */}
      </div>
    </div>
  );
};

export default FormCapture;
