import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import ReactSwitch from "react-switch";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import { useUser } from "../../../../UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./LeadsSubTab.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faSave,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

const LeadsSubTab = () => {
  const { id: chatbotId } = useParams();
  const [title, setTitle] = useState("");
  const [nameEnabled, setNameEnabled] = useState(false);
  const { endpoint, userMetadata, plans} = useUser(); // Destructure userMetadata
  const [emailEnabled, setEmailEnabled] = useState(false);
  const [phoneEnabled, setPhoneEnabled] = useState(false);
  const [leadSettingsId, setLeadSettingsId] = useState(null);
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    const fetchLeadSettings = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/lead-settings/chatbot/${chatbotId}`
        );
        const settings = response.data;
        setTitle(settings.title);
        setNameEnabled(settings.name);
        setEmailEnabled(settings.email);
        setPhoneEnabled(settings.phone);
        setLeadSettingsId(settings.id); // Save the ID for updates
      } catch (err) {
        console.error(err);
        // Handle error: for example, setting default values, showing notifications, etc.
      }
    };

    if (chatbotId) {
      fetchLeadSettings();
    }
  }, [chatbotId, endpoint]);

  const handleSave = async () => {
    const leadSettingsData = {
      title,
      name: nameEnabled,
      email: emailEnabled,
      phone: phoneEnabled,
      chatbot_id: chatbotId,
    };

    try {
      let response;
      if (leadSettingsId) {
        // Update existing settings
        response = await axios.put(
          `${endpoint}/api/lead-settings/${leadSettingsId}`,
          leadSettingsData
        );
      } else {
        // Create new settings
        response = await axios.post(
          `${endpoint}/api/lead-settings`,
          leadSettingsData
        );
      }
      console.log("Lead settings saved:", response.data);
      // Update the ID in case we just created a new entry
      setLeadSettingsId(response.data.id);
      toast.success("Lead settings saved");
    } catch (error) {
      console.error("Error saving lead settings:", error);
      toast.error("Error saving lead settings:", error);
    }
  };

  // Check if the user has the specific product_id
  const isLocked = userMetadata.product_id === plans[0].product_id;

  return (
    <div className="leads-subtab-container">
      {isLocked && (
        <div className="locked-overlay">
          <Button
            className="upgrade-button"
            onClick={() => navigate("/pricing")}
          >
            <FontAwesomeIcon icon={faLock} /> {' '}
            Upgrade Account to Access
          </Button>
        </div>
      )}{" "}
      <div className={isLocked ? "content-blur" : ""}>
        <h4>Lead Capture Form</h4>
        <p>
          <FontAwesomeIcon icon={faInfoCircle} /> Collect leads from your
          chatbot. This form will appear after the first response. Submissions
          will be saved in your <b>Dashboard</b> under the <b>leads tab</b>.
        </p>

        <>
          <FormGroup>
            <Label for="title">Form Title</Label>
            <Input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>

          <h5>Fields to Collect</h5>
          <div className="form-group">
            <label>Name</label>
            <ReactSwitch checked={nameEnabled} onChange={setNameEnabled} />
          </div>

          <div className="form-group">
            <label>Email</label>
            <ReactSwitch checked={emailEnabled} onChange={setEmailEnabled} />
          </div>

          <div className="form-group">
            <label>Phone</label>
            <ReactSwitch checked={phoneEnabled} onChange={setPhoneEnabled} />
          </div>

          {isLocked ? (
            <div className="upgrade-notice">
              <FontAwesomeIcon icon={faLock} />
              <p>This feature is locked. Upgrade to unlock.</p>
              <Button color="primary" onClick={() => navigate("/pricing")}>
                Upgrade
              </Button>
            </div>
          ) : (
            <Button color="primary" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} /> Save
            </Button>
          )}
        </>
        <ToastContainer />
      </div>
    </div>
  );
};

export default LeadsSubTab;
