import React, { useState } from "react";
import { Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faUsers,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import "./DashboardTab.css";
import ChatLogsSubTab from "./ChatLogsSubTab";
import LeadsSubTab from "./LeadsSubTab";
import AnalyticsSubTab from "./AnalyticsSubTab";

const DashboardTab = ({ handleBack }) => {
  const [activeTab, setActiveTab] = useState("leads");

  return (
    <div>
      <Row>
        <Col>
          <h3>Dashboard</h3>
        </Col>
      </Row>
      <br />
      <Row>
        {/* Dashboard Tabs Column */}
        <Col md="3" className="dashboard-column">
          <ListGroup>
            <ListGroupItem
              action
              active={activeTab === "leads"}
              onClick={() => setActiveTab("leads")}
            >
              <FontAwesomeIcon icon={faUsers} /> Leads
            </ListGroupItem>
            <ListGroupItem
              action
              active={activeTab === "chat-logs"}
              onClick={() => setActiveTab("chat-logs")}
            >
              <FontAwesomeIcon icon={faComments} /> Chat Logs
            </ListGroupItem>
            <ListGroupItem
              action
              active={activeTab === "analytics"}
              onClick={() => setActiveTab("analytics")}
            >
              <FontAwesomeIcon icon={faChartBar} /> Analytics
            </ListGroupItem>
          </ListGroup>
        </Col>

        {/* Dashboard Content Column */}
        <Col md="9" className="dashboard-content">
          {activeTab === "leads" && <LeadsSubTab />}
          {activeTab === "chat-logs" && <ChatLogsSubTab />}
          {activeTab === "analytics" && <AnalyticsSubTab />}
        </Col>
      </Row>
    </div>
  );
};

export default DashboardTab;
