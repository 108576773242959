import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../../../../UserContext";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import "./ChatLogsSubTab.css"; // Make sure to create this CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
const { useNavigate } = require("react-router-dom");

const ChatLogsSubTab = () => {
  const { endpoint, userMetadata, plans } = useUser();
  const [chatLogs, setChatLogs] = useState([]);
  const navigate = useNavigate();
  const { id: chatbotId } = useParams();
  const [selectedThread, setSelectedThread] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [isLocked, setIsLocked] = useState(true);


  useEffect(() => {
    let proPlanProductId = plans[0].product_id;
    console.log("proPlanProductId", proPlanProductId);
    console.log("userMetadata.product_id", userMetadata.product_id);
    setIsLocked(userMetadata.product_id === proPlanProductId);
  }, [userMetadata]);

  useEffect(() => {
    const fetchChatLogs = async () => {
      if (!chatbotId) {
        console.error("Chatbot ID is not available");
        return;
      }

      try {
        const response = await axios.get(
          `${endpoint}/api/assistants/${chatbotId}/conversations`
        );
        console.log("Chat logs:", response.data);
        if(response.data.length > 0) handleCardClick(response.data[0].thread_id);
        setChatLogs(response.data);
      } catch (error) {
        console.error("Error fetching chat logs:", error);
      }
    };

    fetchChatLogs();
  }, [chatbotId, endpoint]);
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };
  const handleCardClick = async (threadId) => {
    setSelectedThread(threadId);
    try {
      const response = await axios.get(
        `${endpoint}/api/assistants/${chatbotId}/conversations/${threadId}`
      );
      // sort chat history by timestamp
      if (response.data) {
        response.data = response.data.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      }
      setChatHistory(response.data);
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  return (
    <div className="chat-logs-container">
      {isLocked && (
        <div className="locked-overlay">
          <Button className="upgrade-button" onClick={() => navigate("/pricing")}>
            <FontAwesomeIcon icon={faLock} /> Upgrade Account to Access
          </Button>
        </div>
      )}
      <div className={isLocked ? "content-blur chat-log-list" : ""}>
        <h4>Chat Logs</h4>
        {chatLogs.length === 0 && <p>No chat logs found.</p>}
        {chatLogs.map((log, index) => (
          <Card
            key={index}
            onClick={() => handleCardClick(log.thread_id)}
            className="chat-log-card"
          >
            <CardBody>
              {selectedThread === log.thread_id && (
                <CardTitle
                  style={{
                    color: "#6370FE",
                  }}
                >
                  Visitor (Chat ID: <span>{log.id}</span>)
                </CardTitle>
              )}
              {selectedThread !== log.thread_id && (
                <CardTitle>
                  Visitor (Chat ID: <span>{log.id}</span>)
                </CardTitle>
              )}
              <CardText>Timestamp: {formatTimestamp(log.timestamp)}</CardText>
            </CardBody>
          </Card>
        ))}
      </div>
      <div className="chat-history-container">
        {selectedThread && (
          <>
            <h5>Chat History (Thread ID: {selectedThread})</h5>
            <div className="chat-history">
              {chatHistory.length > 0 ? (
                chatHistory.map((message, index) => (
                  <div key={index} className={`message ${message.role}`}>
                    {message.content[0].text.value}
                  </div>
                ))
              ) : (
                <p>No chat history found.</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatLogsSubTab;
