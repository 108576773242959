import React, { useState, useRef, useEffect } from "react";
import MessageList from "./MessageList";
import InputBar from "./InputBar";
import axios from "axios";
import "./ChatBot.css";
import { useParams } from "react-router-dom";
import { useUser } from "../../UserContext";
import LeadForm from "./LeadForm";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const { endpoint } = useUser();
  // Chatbot Settings
  const [displayName, setDisplayName] = useState("");
  const [theme, setTheme] = useState("light"); // Default theme
  const [initialMessage, setInitialMessage] = useState("");
  const [watermark, setWatermark] = useState(true);
  const [leadSettings, setLeadSettings] = useState({
    title: "",
    nameEnabled: false,
    emailEnabled: false,
    phoneEnabled: false,
  });

  const messagesEndRef = useRef(null);
  const { id: chatbotId } = useParams(); // Call useParams outside of the callback
  const [messagesSent, setMessagesSent] = useState(0);

  useEffect(() => {
    const retrieveChatbotDetailsAndLeads = async () => {
      try {
        // Fetch chatbot details
        const chatbotDetailsResponse = await axios.get(
          `${endpoint}/api/chatbots/${chatbotId}`
        );
        const { theme, initial_message, display_name, watermark } =
          chatbotDetailsResponse.data;
        setTheme(theme);
        setInitialMessage(initial_message);
        setDisplayName(display_name);
        setWatermark(watermark);
        if (initial_message) {
          setMessages([
            {
              role: "assistant",
              content: [{ text: { value: initial_message } }],
            },
          ]);
        }

        // Fetch lead settings
        const leadSettingsResponse = await axios.get(
          `${endpoint}/api/lead-settings/chatbot/${chatbotId}`
        );
        if (leadSettingsResponse.data) {
          console.log("Lead settings:", leadSettingsResponse.data);
          setLeadSettings(leadSettingsResponse.data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (chatbotId) {
      retrieveChatbotDetailsAndLeads();
    }
  }, [chatbotId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = async (messageText) => {
    console.log("Sending message:", messageText);
    setIsTyping(true);
    const userMessage = {
      role: "user",
      content: [{ text: { value: messageText } }],
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    try {
      console.log("Sending message:(in try block)", messageText);
      const response = await axios.post(`${endpoint}/chatbot/send-message`, {
        chatbotId,
        threadId,
        prompt: messageText,
      });
      console.log("Response from send message:", response.data);
      const newMessages = response.data.messages;
      let updatedMessages;
      if(messages){
        updatedMessages = [messages[0], ...newMessages];
      }else{
        updatedMessages = [...newMessages];
      }
      console.log("Updated messages:", updatedMessages);
      if (messagesSent === 0 && (leadSettings.name || leadSettings.email || leadSettings.phone)) {
        const leadFormMessage = {
          role: "lead-capture",
          content: [
            {
              component: (
                <LeadForm
                  leadSettings={leadSettings}
                  onCancel={() => {
                    setMessages((prevMessages) =>
                      prevMessages.filter((msg) => msg.role !== "lead-capture")
                    );
                  }}
                  onSubmit={async (leadData) => {
                    console.log("Lead data submitted:", leadData);
                    setMessages((prevMessages) =>
                      prevMessages.filter((msg) => msg.role !== "lead-capture")
                    );

                    try {
                      const response = await axios.post(
                        `${endpoint}/api/leads`,
                        {
                          chatbotId,
                          ...leadData,
                        }
                      );
                      console.log("Lead saved:", response.data);
                      toast.success("Form submitted successfully!");
                    } catch (error) {
                      console.error("Error saving lead:", error);
                      toast.error("Error submitting form.");
                    }
                  }}
                />
              ),
            },
          ],
        };
        updatedMessages.push(leadFormMessage);
      }

      setMessages(updatedMessages);
      setThreadId(response.data.threadId);
      setMessagesSent((prevCount) => prevCount + 1);
    } catch (err) {
      console.error(err);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "assistant",
          content: [
            {
              text: { value: "Sorry, I'm not able to respond at the moment." },
            },
          ],
        },
      ]);
    }

    setIsTyping(false);
  };

  return (
    <div className={`chat-container ${theme}`}>
      {displayName && <div className="chatbot-display-name">{displayName}</div>}
      <div className="message-list">
        <MessageList
          ref={messagesEndRef}
          messages={messages}
          isTyping={isTyping}
        />
      </div>
      <div className="input-bar">
        <InputBar onSend={sendMessage} isTyping={isTyping} watermark={watermark} />
      </div>
      <ToastContainer />
    </div>
  );
};

export default ChatBot;
