import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Input,
  Button,
  Progress,
  FormGroup,
  Label,
} from "reactstrap";
import "./SourcesTab.css";
import axios from "axios";
import { useUser } from "../../../../UserContext";
import { useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faEarth,
  faFile,
  faLock,
  faPencil,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import "pdfjs-dist/legacy/build/pdf.worker";
import { Link } from "react-router-dom";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const SourcesTab = ({ handleBack }) => {
  const [activeSource, setActiveSource] = useState("file");
  const [files, setFiles] = useState([]);
  const [pendingFile, setPendingFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { endpoint, userMetadata, plans } = useUser();
  const { id: chatbotId } = useParams();
  const [text, setText] = useState("");
  const [characterLimit, setCharacterLimit] = useState(400000); // 400,000 characters
  const [pendingTextCharacterCount, setPendingTextCharacterCount] = useState(0);
  const [pendingDocumentCharacterCount, setPendingDocumentCharacterCount] =
    useState(0);

  useEffect(() => {
    if (userMetadata) {
      let plan = plans.find(
        (plan) => plan.product_id === userMetadata.product_id
      );
      setCharacterLimit(plan.character_limit);
    }
  }, [userMetadata]);
  const fetchFiles = async () => {
    console.log(
      "Character count",
      pendingDocumentCharacterCount + pendingTextCharacterCount
    );
    try {
      const response = await axios.get(
        `${endpoint}/api/chatbots/${chatbotId}/files`
      );
      console.log(response.data);
      let filesResponse = response.data;
      let filesCharacterCount = 0;
      filesResponse = await filesResponse.map((file) => {
        if (file.type === "file") {
          filesCharacterCount += file?.character_count || 0;
        }
      });
      console.log({ filesCharacterCount });
      setPendingDocumentCharacterCount(filesCharacterCount);
      setFiles(response.data); // Set the fetched files without filtering
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };
  const fetchChatbotData = async () => {
    try {
      const response = await axios.get(`${endpoint}/api/chatbots/${chatbotId}`);
      console.log(response.data);
      if (response.data.text_source) {
        setText(response.data.text_source);
        setPendingTextCharacterCount(response.data.text_source.length);
      }
    } catch (error) {
      console.error("Error fetching chatbot data:", error);
    }
  };
  useEffect(() => {
    fetchFiles();
    fetchChatbotData();
  }, [chatbotId]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    let charCount = 0;
    if (file.type === "application/pdf") {
      charCount = await countCharactersInPDF(file);
    } else if (file.type === "text/plain") {
      charCount = await countCharactersInTextFile(file);
    }

    setPendingFile({
      file_name: file.name,
      size: file.size,
      file,
      uploaded: false,
      characterCount: charCount,
    });
    console.log({
      pendingDocumentCharacterCount,
      charCount,
    });
    setPendingDocumentCharacterCount(pendingDocumentCharacterCount + charCount);
  };

  const handleTextChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    setPendingTextCharacterCount(newText.length);
  };

  // Function to count characters in PDF file
  const countCharactersInPDF = async (file) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    const arrayBuffer = await new Promise((resolve) => {
      reader.onload = () => resolve(reader.result);
    });

    const pdfDocument = await pdfjsLib.getDocument({ data: arrayBuffer })
      .promise;
    let totalCharacters = 0;
    for (let i = 1; i <= pdfDocument.numPages; i++) {
      const page = await pdfDocument.getPage(i);
      const textContent = await page.getTextContent();
      totalCharacters += textContent.items
        .map((item) => item.str)
        .join("").length;
    }
    return totalCharacters;
  };

  // Function to count characters in text file
  const countCharactersInTextFile = async (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    const textContent = await new Promise((resolve) => {
      reader.onload = () => resolve(reader.result);
    });
    return textContent.length;
  };

  const handleRetrainChatbot = async () => {
    setIsLoading(true);

    try {
      console.log({ pendingDocumentCharacterCount, pendingTextCharacterCount });
      if (
        pendingDocumentCharacterCount + pendingTextCharacterCount >
        characterLimit
      ) {
        toast.error(
          "Character limit exceeded. Please reduce the content or upgrade your account."
        );
        return;
      }

      // Handle file upload logic if there's a pending file
      if (pendingFile && activeSource === "file") {
        const formData = new FormData();
        formData.append("file", pendingFile.file);
        // Append the character count to formData
        formData.append("character_count", pendingFile.characterCount);

        await axios.post(
          `${endpoint}/api/chatbots/${chatbotId}/upload/file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      // Handle text source logic if text is provided
      if (text && activeSource === "text") {
        await axios.put(`${endpoint}/api/chatbots/${chatbotId}/text-source`, {
          text_source: text,
          character_count: text.length, // This assumes you are keeping track of the character count of text
        });
      }

      setProgress(100);

      toast.success("Data uploaded and chatbot retrained successfully");
      // Fetch updated files list and reset states
      await fetchFiles();
      setPendingFile(null);
    } catch (error) {
      console.error("Error uploading data:", error);
      toast.error("Error uploading data");
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  const handleDeleteFile = async (index) => {
    try {
      await axios.delete(
        `${endpoint}/api/chatbots/${chatbotId}/files/${index}`
      );
      files.map((file, fileIndex) => {
        console.log({ fileIndex, index, id: file.id });
      });
      setFiles(files.filter((file, fileIndex) => file.id !== index));
      setPendingDocumentCharacterCount(
        pendingDocumentCharacterCount -
          files.find((file) => file.id === index).character_count
      );
      toast.success("File deleted successfully");
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.error("Error deleting file");
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <h3>Data Sources</h3>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md="3" className="sources-column">
          <ListGroup>
            <ListGroupItem
              action
              active={activeSource === "file"}
              onClick={() => setActiveSource("file")}
            >
              <FontAwesomeIcon icon={faFile} /> File
            </ListGroupItem>
            <ListGroupItem
              action
              active={activeSource === "text"}
              onClick={() => setActiveSource("text")}
            >
              <FontAwesomeIcon icon={faPencil} /> Text
            </ListGroupItem>
            <ListGroupItem
              action
              active={activeSource === "website"}
              onClick={() => setActiveSource("website")}
              disabled
            >
              <FontAwesomeIcon icon={faEarth} /> Website (coming soon)
            </ListGroupItem>
          </ListGroup>
        </Col>
        <Col md="6" className="input-column">
          <h5>Data Input</h5>
          {activeSource === "file" && (
            <>
              <Input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                accept="application/pdf,text/plain"
              />
              <p
                style={{
                  fontSize: 12,
                  color: "grey",
                  marginTop: 15,
                }}
              >
                Only .txt and .pdf files are supported at this time. Please
                upload your data to retrain the chatbot.
              </p>
              {pendingFile && (
                <>
                  <div class="">Attached Files</div>
                  <br />
                  <ListGroup>
                    <ListGroupItem>
                      {pendingFile.file_name}{" "}
                      <span style={{ fontSize: 14 }}>
                        ({pendingFile.characterCount.toLocaleString()}{" "}
                        characters)
                      </span>
                      <div
                        style={{
                          fontSize: 12,
                        }}
                      >
                        (please click <b>Retrain Chatbot</b> to upload and
                        retrain the chatbot)
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </>
              )}
              <hr />

              <div class="">Previously Uploaded Files</div>
              <br />
              <ListGroup>
                {files.map((file, index) => {
                  if (file.type === "file") {
                    return (
                      <ListGroupItem key={file.id}>
                        {file.file_name}{" "}
                        <span
                          style={{
                            fontSize: 14,
                          }}
                        >
                          ({file.character_count.toLocaleString()} characters)
                        </span>
                        <FontAwesomeIcon
                          style={{
                            cursor: "pointer",
                            color: "red",
                            paddingTop: "5px",
                            float: "right",
                          }}
                          icon={faTrashAlt}
                          onClick={() => handleDeleteFile(file.id)}
                        />
                      </ListGroupItem>
                    );
                  }
                })}
              </ListGroup>
              <br />
            </>
          )}
          {activeSource === "text" && (
            <FormGroup>
              <Label for="textInput">Enter Text</Label>
              <Input
                type="textarea"
                id="textInput"
                value={text}
                placeholder="data"
                onChange={handleTextChange}
              />
              <p
                style={{
                  fontSize: 12,
                  color: "grey",
                  marginTop: 15,
                }}
              >
                Fun hint: You can copy and paste text from a website or a
                document here to retrain the chatbot.
              </p>
            </FormGroup>
          )}
        </Col>
        <Col md="3">
          <div className="character-counter">
            <h5>Sources</h5>
            <div>
              <b>
                Total Files:{" "}
                {files.filter((file) => file.type === "file").length}
              </b>
            </div>
            Total detected characters
            <div>
              {(
                pendingDocumentCharacterCount + pendingTextCharacterCount
              )?.toLocaleString()}
              / {characterLimit.toLocaleString()} limit
            </div>
            <br />
            {pendingDocumentCharacterCount + pendingTextCharacterCount >
              characterLimit && (
              <>
                <p style={{ color: "red" }}>
                  Character limit exceeded. Please reduce the content or upgrade
                  your account.
                </p>
                <Button
                  style={{
                    background: "gold",
                    fontWeight: "600",
                    color: "black",
                    border: "none",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />{" "}
                  <Link
                    to="/pricing"
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Upgrade Account
                  </Link>
                </Button>
                <br />
                <br />
              </>
            )}
            {pendingDocumentCharacterCount + pendingTextCharacterCount <=
              characterLimit && (
              <Button
                style={{
                  background: "#6666FF",
                  fontWeight: "600",
                  border: "none",
                }}
                disabled={isLoading}
                onClick={handleRetrainChatbot}
              >
                {isLoading ? "Training chatbot..." : "Retrain Chatbot"}
              </Button>
            )}
            {isLoading && <Progress value={progress} />}
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default SourcesTab;
