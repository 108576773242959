import React, { useState, useEffect } from "react";
import "./Pricing.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCreditCard,
  faInfo,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../UserContext";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import RetrieveCard from "./RetrieveCard";
import PreviewInvoiceCard from "./PreviewInvoiceCard";

const PlanFeature = ({ included, text }) => (
  <div className="plan-feature">
    <FontAwesomeIcon
      icon={included ? faCheck : faTimes}
      className={included ? "check-icon" : "times-icon"}
    />
    <span>{text}</span>
  </div>
);

const ProductDisplay = ({
  userMetadata,
  createCheckoutSession,
  plans,
  subscribedPlan,
  upgradeSubscription,
  handleDowngradeClick,
  handleUpgradeClick,
}) => (
  <section className="product-display">
    <div className="product free-plan">
      <div className="plan-header">
        <h3>Free</h3>
      </div>
      <div className="plan-features">
        <PlanFeature included={true} text="20 message credits/month" />
        <PlanFeature included={true} text="1 chatbot" />
        <PlanFeature included={true} text="400,000 characters/chatbot" />
        <PlanFeature included={true} text="Limit to 10 links for training" />
        <PlanFeature included={true} text="Embed on unlimited websites" />
        <PlanFeature included={true} text="Upload multiple files" />
        <PlanFeature
          included={true}
          text="Chatbots deleted after 7 days of inactivity"
        />
        <PlanFeature included={false} text="View conversation history" />
        <PlanFeature included={false} text="Capture leads" />
        <PlanFeature included={false} text="Webhooks" />
      </div>
      <div className="plan-subscribe">
        <h5>${plans[0].price}</h5>
        {subscribedPlan === plans[0].product_id && (
          <button
            className="plan-button"
            style={{ backgroundColor: "green" }}
            onClick={() => {
              // Call the function to create a Checkout session
              // This function is defined in the next section
              toast.success("You are already subscribed to the free plan!");
            }}
          >
            Subscribed
          </button>
        )}
        {subscribedPlan !== plans[0].product_id && (
          <button
            className="plan-button"
            onClick={() => {
              // Call the function to create a Checkout session
              // This function is defined in the next section
              handleDowngradeClick(plans[0].product_id);
            }}
          >
            Downgrade
          </button>
        )}
      </div>
    </div>
    <div className="product hobby-plan">
      <div className="plan-header">
        <h3>Hobby</h3>
      </div>
      <div className="plan-features">
        <PlanFeature included={true} text="2,000 message credits/month" />
        <PlanFeature included={true} text="2 chatbots" />
        <PlanFeature included={true} text="1,000,000 characters/chatbot" />
        <PlanFeature included={true} text="Unlimited links for training" />
        <PlanFeature included={true} text="Embed on unlimited websites" />
        <PlanFeature included={true} text="Upload multiple files" />
        <PlanFeature
          included={true}
          text="Chatbots never deleted due to inactivity"
        />
        <PlanFeature included={true} text="View conversation history" />
        <PlanFeature included={true} text="Capture leads" />
        <PlanFeature included={false} text="Advanced Analytics" />
        <PlanFeature included={false} text="Webhooks" />
        <PlanFeature included={false} text="Option to choose GPT-4" />
        <PlanFeature included={false} text="Remove Watermark" />
      </div>
      <div className="plan-subscribe">
        <h5>${plans[1].price} / month</h5>
        {subscribedPlan === plans[1].product_id ? (
          <button className="plan-button" style={{ backgroundColor: "green" }}>
            Subscribed
          </button>
        ) : subscribedPlan === plans[2].product_id ? (
          <button
            className="plan-button"
            onClick={() => handleDowngradeClick(plans[1].product_id)}
          >
            Downgrade
          </button>
        ) : userMetadata?.subscription_id ? (
          <button
            className="plan-button"
            onClick={() => handleUpgradeClick(plans[1].product_id)}
          >
            Upgrade
          </button>
        ) : (
          <button
            className="plan-button"
            onClick={() => createCheckoutSession(plans[1].price_id)}
          >
            Subscribe
          </button>
        )}
      </div>
    </div>
    <div className="product pro-plan">
      <div className="plan-header">
        <h3 style={{
        }}>Pro</h3>
      </div>
      <div className="plan-features">
        <PlanFeature included={true} text="10,000 message credits/month" />
        <PlanFeature included={true} text="5 chatbots" />
        <PlanFeature included={true} text="1,000,000 characters/chatbot" />
        <PlanFeature included={true} text="Unlimited links for training" />
        <PlanFeature included={true} text="Embed on unlimited websites" />
        <PlanFeature included={true} text="Upload multiple files" />
        <PlanFeature
          included={true}
          text="Chatbots never deleted due to inactivity"
        />
        <PlanFeature included={true} text="View conversation history" />
        <PlanFeature included={true} text="Capture leads" />
        <PlanFeature included={true} text="Advanced Analytics" />
        <PlanFeature included={true} text="Webhooks" />
        <PlanFeature included={true} text="Option to choose GPT-4" />
        <PlanFeature included={true} text="Remove Watermark" />
      </div>
      <div className="plan-subscribe">
        <h5>${plans[2].price} / month</h5>
        {subscribedPlan === plans[2].product_id ? (
          <button className="plan-button" style={{ backgroundColor: "green" }}>
            Subscribed
          </button>
        ) : subscribedPlan === plans[1].product_id ? (
          <button
            className="plan-button"
            onClick={() => handleUpgradeClick(plans[2].product_id)}
          >
            Upgrade
          </button>
        ) : userMetadata?.subscription_id ? (
          <button
            className="plan-button"
            onClick={() => handleUpgradeClick(plans[2].product_id)}
          >
            Upgrade
          </button>
        ) : (
          <button
            className="plan-button"
            onClick={() => createCheckoutSession(plans[2].price_id)}
          >
            Subscribe
          </button>
        )}
      </div>
    </div>
  </section>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section className="success-display">
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Successfully subscribed!</h3>
        </div>
      </div>
      <form action="/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <Link to="/chatbot-list" id="checkout-and-portal-button" type="submit">
          Go To My Chatbots
        </Link>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section className="message-display">
    <p>{message}</p>
  </section>
);

export default function Checkout() {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedChatbots, setSelectedChatbots] = useState([]);
  const [portalUrl, setPortalUrl] = useState("");
  const [chatbots, setChatbots] = useState([]);
  const [newPlan, setNewPlan] = useState(null);
  const { userMetadata, endpoint, plans, setUserMetadata } = useUser();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isPaying, setIsPaying] = useState(false);


  useEffect(() => {
    if (!userMetadata) {
      return;
    }
    const fetchChatbots = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/users/${userMetadata.id}/chatbots`
        );
        setChatbots(response.data);
      } catch (error) {
        console.error("Error fetching chatbots:", error);
        toast.error("Error fetching chatbots.");
      }
    };
    const fetchPortalUrl = async () => {
      try {
        if (!userMetadata.stripe_customer_id) {
          return;
        }
        const response = await axios.post(`${endpoint}/create-portal-session`, {
          customerId: userMetadata.stripe_customer_id,
        });
        setPortalUrl(response.data.url);
      } catch (error) {
        console.error("Error fetching portal URL:", error);
      }
    };

    fetchPortalUrl();
    fetchChatbots();
  }, [userMetadata, endpoint]);

  const handleSubmitModal = async () => {
    try {
      for (const chatbot of chatbots) {
        await axios.put(`${endpoint}/api/chatbots/${chatbot.id}/active`, {
          active: selectedChatbots.includes(chatbot.id),
        });
      }
      upgradeSubscription(newPlan.price_id);
    } catch (error) {
      console.error("Error updating chatbot status:", error);
      toast.error("Error updating chatbot status.");
    } finally {
      setShowModal(false);
    }
  };

  const createCheckoutSession = async (priceId) => {
    if (!userMetadata.stripe_customer_id) {
      setMessage(
        "Sorry, we haven't been able to create a customer account for you yet. Please contact our support."
      );
      toast.error("No customer account found.");
      return;
    } else if (userMetadata.subscription_id) {
      upgradeSubscription(priceId);
      return;
    }

    try {
      const response = await axios.post(`${endpoint}/create-checkout-session`, {
        priceId,
        customerId: userMetadata.stripe_customer_id,
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error creating checkout session:", error);
      toast.error("Error creating checkout session.");
    }
  };
  const handleUpgradeClick = (planId) => {
    try {
      const plan = plans.find((plan) => plan.product_id === planId);
      if (userMetadata.stripe_customer_id) {
        window.location.href = portalUrl;
      } else {
        setMessage(
          "Sorry, we haven't been able to create a customer account for you yet. Please contact our support."
        );
        toast.error("No customer account found.");
      }
    } catch (error) {
      console.error("Error upgrading subscription:", error);
      toast.error("Error upgrading subscription.");
    }
  };

  const handleDowngradeClick = (planId) => {
    try {
      if (userMetadata.stripe_customer_id) {
        window.location.href = portalUrl;
      } else {
        setMessage(
          "Sorry, we haven't been able to create a customer account for you yet. Please contact our support."
        );
        toast.error("No customer account found.");
      }
    } catch (error) {
      console.error("Error downgrading subscription:", error);
      toast.error("Error downgrading subscription.");
    }
  };
  // const handleUpgradeClick = (planId) => {
  //   try {
  //     const plan = plans.find((plan) => plan.product_id === planId);
  //     // check for inactive chatbots
  //     let inactiveChatbots = chatbots.filter((chatbot) => !chatbot.active);
  //     let activeChatbots = chatbots.filter((chatbot) => chatbot.active);
  //     console.log("Active chatbots:", activeChatbots);
  //     console.log("Inactive chatbots:", inactiveChatbots);
  //     console.log("Plan chatbots limit:", plan.chatbots);
  //     if (chatbots.length > 0) {
  //       setShowModal(true);
  //       setSelectedChatbots(
  //         activeChatbots.slice(0, plan.chatbots).map((chatbot) => chatbot.id)
  //       );
  //       console.log("Selected chatbots:", selectedChatbots);
  //       console.log("Inactive chatbots:", inactiveChatbots);
  //       console.log("Plan chatbots limit:", plan.chatbots);
  //       setNewPlan(plan);
  //     } else {
  //       // upgradeSubscription(plan.price_id);
  //       setNewPlan(plan);
  //       setShowPaymentModal(true);
  //     }
  //   } catch (error) {
  //     console.error("Error upgrading subscription:", error);
  //     toast.error("Error upgrading subscription.");
  //   }
  // };

  // const handleDowngradeClick = (planId) => {
  //   try {
  //     const plan = plans.find((plan) => plan.product_id === planId);
  //     let inactiveChatbots = chatbots.filter((chatbot) => !chatbot.active);
  //     if (chatbots.length > plan.chatbots || inactiveChatbots.length > 0) {
  //       setShowModal(true);
  //       setSelectedChatbots(
  //         chatbots.slice(0, plan.chatbots).map((chatbot) => chatbot.id)
  //       );
  //       setNewPlan(plan);
  //     } else {
  //       setNewPlan(plan);
  //       setShowPaymentModal(true);
  //       // upgradeSubscription(plan.price_id);
  //     }
  //   } catch (error) {
  //     console.error("Error downgrading subscription:", error);
  //     toast.error("Error downgrading subscription.");
  //   }
  // };

  const handleChatbotSelection = (chatbotId) => {
    setSelectedChatbots((prevSelected) =>
      prevSelected.includes(chatbotId)
        ? prevSelected.filter((id) => id !== chatbotId)
        : [...prevSelected, chatbotId]
    );
  };

  const upgradeSubscription = async (newPriceId) => {
    try {
      setIsPaying(true);
      const response = await axios.post(`${endpoint}/upgrade-subscription`, {
        subscriptionId: userMetadata.subscription_id,
        newPriceId,
      });
      setUserMetadata((prevMetadata) => ({
        ...prevMetadata,
        product_id: response.data.plan.product,
      }));
      toast.success("Subscription upgraded successfully!");
      setShowPaymentModal(false);
      setIsPaying(false);
    } catch (error) {
      setIsPaying(false);
      console.error("Error upgrading subscription:", error);
      toast.error("Error upgrading subscription.");
      setShowPaymentModal(false);
    }
  };

  useEffect(() => {
    try{
      const query = new URLSearchParams(window.location.search);
      console.log("Query:", query);
      if (query.get("success")) {
        setSuccess(true);
        setSessionId(query.get("session_id"));
      } else if (query.get("canceled")) {
        setSuccess(false);
        setMessage(
          "Order canceled. Continue to shop around and checkout when you're ready."
        );
      }
    } catch (error) {
      console.error("Error handling checkout session:", error);
      toast.error("Error handling checkout session.");
    }
  }, [sessionId]);

  if (!success && message === "") {
    return (
      <>
        {" "}
        <div
          style={{
            textAlign: "center",
            fontSize: "60px",
            fontWeight: "800",
          }}
        >
          Pricing Plans
        </div>
        <ProductDisplay
          userMetadata={userMetadata}
          createCheckoutSession={createCheckoutSession}
          plans={plans}
          upgradeSubscription={upgradeSubscription}
          handleDowngradeClick={handleDowngradeClick}
          subscribedPlan={userMetadata?.product_id}
          handleUpgradeClick={handleUpgradeClick}
        />
        <ToastContainer />
        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(!showModal)}>
            Confirm Chatbots to Keep Active
          </ModalHeader>
          <ModalBody>
            <p>
              Select the chatbots you want to keep active (up to{" "}
              {newPlan?.chatbots}):
            </p>
            {chatbots &&
              chatbots.map((chatbot) => (
                <FormGroup check key={chatbot.id}>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={selectedChatbots.includes(chatbot.id)}
                      onChange={() => handleChatbotSelection(chatbot.id)}
                      disabled={
                        !selectedChatbots.includes(chatbot.id) &&
                        selectedChatbots.length >= newPlan?.chatbots
                      }
                    />
                    {chatbot.name}
                  </Label>
                </FormGroup>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                setShowModal(false);
                setShowPaymentModal(true);
              }}
            >
              Confirm
            </Button>
            <Button color="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showPaymentModal}
          toggle={() => setShowPaymentModal(!showPaymentModal)}
        >
          <ModalHeader toggle={() => setShowPaymentModal(!showPaymentModal)}>
            Confirm Payment
          </ModalHeader>
          <ModalBody>
            <p>Please pay to update your subscription.</p>
            <p>
              <b>Current Plan:</b>{" "}
              {
                plans.find(
                  (plan) => plan?.product_id === userMetadata?.product_id
                )?.name
              }
            </p>
            <p>
              <b>New Plan:</b> {newPlan?.name}
            </p>
            <p>
              <b>Price:</b> ${newPlan?.price}
            </p>
            <hr />
            {userMetadata?.subscription_id ? (
              <PreviewInvoiceCard
                priceId={newPlan?.price_id}
              ></PreviewInvoiceCard>
            ) : (
              ""
            )}
            <RetrieveCard></RetrieveCard>

            <Link to="/account" id="checkout-and-portal-button" type="submit">
              Manage your billing information
            </Link>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleSubmitModal}
              disabled={isPaying}
            >
              {isPaying ? "Paying..." : "Pay Now"}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setShowPaymentModal(false);
                setSelectedChatbots([]);
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  } else if (success && sessionId !== "") {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}
