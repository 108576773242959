import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Label,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faSave } from "@fortawesome/free-solid-svg-icons";
import "./WebhookSubTab.css";
import { toast, ToastContainer } from "react-toastify";
import { useUser } from "../../../../UserContext";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

const WebhookList = ({ webhooks, onUpdate, onDelete }) => {
  return (
    <ListGroup>
      <div className="d-flex justify-content-between align-items-center">
        <h4>Webhooks</h4>
      </div>
      {webhooks.length === 0 && (
        <p className="text-muted">
          No webhooks found. Create one to get started.
        </p>
      )}
      {webhooks.map((webhook) => (
        <ListGroupItem
          key={webhook.id}
          className="d-flex justify-content-between align-items-center"
        >
          <div>
            <h5 className="mb-1">
              Events:{" "}
              {webhook.events?.map((event, index) => (
                <Badge key={index} color="primary" className="me-1">
                  {event}
                </Badge>
              ))}
            </h5>
            <p className="mb-1">Endpoint: {webhook.endpoint}</p>
          </div>
          <ButtonGroup>
            <Button color="danger" onClick={() => onDelete(webhook.id)}>
              Delete
            </Button>
          </ButtonGroup>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

const WebhookSetup = ({ addWebhook }) => {
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [webhookEndpoint, setWebhookEndpoint] = useState("");

  const handleEventChange = (event) => {
    if (selectedEvents.includes(event)) {
      setSelectedEvents(selectedEvents.filter((e) => e !== event));
    } else {
      setSelectedEvents([...selectedEvents, event]);
    }
  };

  const handleSave = () => {
    if (selectedEvents.length === 0) {
      toast.error("At least one event must be selected");
      return;
    }

    if (webhookEndpoint === "") {
      toast.error("Endpoint must be provided");
      return;
    }

    // Check if valid URL
    const urlRegex = new RegExp(
      "^(http|https)://([a-zA-Z0-9-]+.)+[a-zA-Z]{2,}(/[a-zA-Z0-9-_.?=/#&%]*)?$"
    );
    if (!urlRegex.test(webhookEndpoint)) {
      toast.error(
        "Invalid URL. Please provide a valid URL. e.g., https://mywebsite.com/webhooks"
      );
      return;
    }

    addWebhook({ events: selectedEvents, endpoint: webhookEndpoint });
    setSelectedEvents([]);
    setWebhookEndpoint("");
  };

  return (
    <div className="webhook-setup-container">
      <h4>Create Webhook</h4>
      <p>
        Select the events the webhook will listen to. At least one must be
        selected.
      </p>
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            checked={selectedEvents.includes("leadsSubmit")}
            onChange={() => handleEventChange("leadsSubmit")}
          />{" "}
          Leads submitted
        </Label>
      </FormGroup>

      <FormGroup>
        <Label for="endpoint">Endpoint</Label>
        <Input
          type="url"
          id="endpoint"
          value={webhookEndpoint}
          onChange={(e) => setWebhookEndpoint(e.target.value)}
          placeholder="https://mywebsite.com/webhooks"
        />
      </FormGroup>

      <Button color="primary" onClick={handleSave}>
        Create Webhook
      </Button>
    </div>
  );
};

const WebhookSubTab = () => {
  const [webhooks, setWebhooks] = useState([]);
  const { id: chatbotId } = useParams(); // Call useParams outside of the callback
  const { endpoint, userMetadata, plans } = useUser(); // Use the endpoint from useUser hook
  const isLocked = userMetadata.product_id === plans[0].product_id;
  const navigate = useNavigate();
  useEffect(() => {
    const fetchWebhooks = async () => {
      try {
        const response = await fetch(`${endpoint}/api/webhooks/${chatbotId}`);
        if (response.ok) {
          const data = await response.json();
          setWebhooks(data);
        } else {
          throw new Error("Failed to fetch webhooks");
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch webhooks");
      }
    };

    fetchWebhooks();
  }, [endpoint]);

  const addWebhook = async (webhook) => {
    webhook = { ...webhook, chatbotId }; // Add chatbotId to the webhook object
    try {
      const response = await fetch(`${endpoint}/api/webhooks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(webhook),
      });

      if (response.ok) {
        const savedWebhook = await response.json();
        setWebhooks([...webhooks, savedWebhook]);
        toast.success("Webhook added successfully");
      } else {
        throw new Error("Failed to add webhook");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to add webhook");
    }
  };

  const updateWebhook = async (updatedWebhook) => {
    updatedWebhook = { ...updatedWebhook, chatbotId }; // Add chatbotId to the updatedWebhook object
    try {
      const response = await fetch(
        `${endpoint}/api/webhooks/${updatedWebhook.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedWebhook),
        }
      );

      if (response.ok) {
        const newWebhooks = webhooks.map((webhook) =>
          webhook.id === updatedWebhook.id ? updatedWebhook : webhook
        );
        setWebhooks(newWebhooks);
        toast.success("Webhook updated successfully");
      } else {
        throw new Error("Failed to update webhook");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update webhook");
    }
  };

  const deleteWebhook = async (webhookId) => {
    try {
      const response = await fetch(`${endpoint}/api/webhooks/${webhookId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        const newWebhooks = webhooks.filter(
          (webhook) => webhook.id !== webhookId
        );
        setWebhooks(newWebhooks);
        toast.success("Webhook deleted successfully");
      } else {
        throw new Error("Failed to delete webhook");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete webhook");
    }
  };

  return (
    <div style={{
      position: "relative",
    }}>
      <WebhookSetup addWebhook={addWebhook} />
      <hr />
      {isLocked && (
        <div className="locked-overlay">
          <Button
            className="upgrade-button"
            onClick={() => navigate("/pricing")}
          >
            <FontAwesomeIcon icon={faLock} /> Upgrade Account to Access
          </Button>
        </div>
      )}{" "}
      <div className={isLocked ? "content-blur" : ""}>
        <WebhookList
          webhooks={webhooks}
          onUpdate={updateWebhook}
          onDelete={deleteWebhook}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default WebhookSubTab;
