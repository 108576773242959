import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { useUser } from "../../UserContext";
import "./Account.css";
import { Link } from "react-router-dom";

const Account = () => {
  const [portalUrl, setPortalUrl] = useState("");
  const { userMetadata, endpoint, plans } = useUser();
  const [planName, setPlanName] = useState("");

  useEffect(() => {
    const fetchPortalUrl = async () => {
      try {
        const response = await axios.post(`${endpoint}/create-portal-session`, {
          customerId: userMetadata.stripe_customer_id,
        });
        setPortalUrl(response.data.url);
      } catch (error) {
        console.error("Error fetching portal URL:", error);
      }
    };

    if (userMetadata?.stripe_customer_id) {
      console.log({ plans });
      plans.forEach((plan) => {
        console.log({ plan });
        if (plan.product_id === userMetadata.product_id) {
          setPlanName(plan.name);
        }
      });
    }else{
      setPlanName(plans[0].name);
    }

    fetchPortalUrl();
  }, [userMetadata, endpoint]);

  return (
    <div className="account-page">
      <div className="page-header account-header">Account Management</div>
      <div className="account-content">
        <h3>Welcome, {userMetadata?.username}!</h3>
        {portalUrl && (
          <>
            <p>
              Manage your subscription and billing information below.
            </p>
            <Button
              className="billing-btn"
              onClick={() => (window.location.href = portalUrl)}
            >
              Manage Subscription
            </Button>
            <br />
            <br />
          </>
        )}
        <div>
          You are currently subscribed to the <b>{planName}</b> plan.
          <br />
          <br />
          Want to upgrade? <Link to="/pricing">View plans</Link>
        </div>
      </div>
    </div>
  );
};

export default Account;
