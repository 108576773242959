import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faSave } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useParams } from "react-router";
import { useUser } from "../../../../UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ChatInterfaceSubTab.css";
import ReactSwitch from "react-switch";
import { useNavigate } from "react-router-dom";
import ChatBot from "../../../ChatBot/ChatBot";
const ChatInterfaceSubTab = () => {
  const [theme, setTheme] = useState("not selected");
  const [initialMessage, setInitialMessage] = useState("");
  const { endpoint, userMetadata, plans } = useUser();
  const [displayName, setDisplayName] = useState("");
  const { id: chatbotId } = useParams(); // Call useParams outside of the callback
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [watermarkEnabled, setWatermarkEnabled] = useState(true);
  const [isWatermarkLocked, setIsWatermarkLocked] = useState(false);
  // const [suggestedMessages, setSuggestedMessages] = useState("");
  const [chatbotKey, setChatbotKey] = useState(0);

  const handleWatermarkChange = (event) => {
    if (!isWatermarkLocked) {
      setWatermarkEnabled(!watermarkEnabled);
      setIsSaveEnabled(true);
    }
  };

  // const handleSuggestedMessagesChange = (event) => {
  //   setSuggestedMessages(event.target.value);
  //   setIsSaveEnabled(true);
  // };

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
    setIsSaveEnabled(true);
  };

  const handleDisplayNameChange = (event) => {
    setDisplayName(event.target.value);
    setIsSaveEnabled(true);
  };

  const handleInitialMessageChange = (event) => {
    setInitialMessage(event.target.value);
    setIsSaveEnabled(true);
  };
  const navigate = useNavigate(); // Call useNavigate to get the navigate function

  useEffect(() => {
    const fetchChatbotData = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/chatbots/${chatbotId}`
        );
        setTheme(response.data.theme);
        setInitialMessage(response.data.initial_message);
        setDisplayName(response.data.display_name);
        setWatermarkEnabled(response.data.watermark);
        // Check if the product ID is present in the userMetadata
        if (userMetadata?.product_id !== plans[2].product_id) {
          setIsWatermarkLocked(true);
        } else {
          setIsWatermarkLocked(false);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchChatbotData();
  }, [chatbotId, userMetadata]);

  const saveChanges = async () => {
    setIsSaveEnabled(false);
    try {
      const response = await axios.put(
        `${endpoint}/api/chatbots/${chatbotId}/settings`,
        {
          theme,
          initialMessage,
          displayName,
          watermark: watermarkEnabled,
          // suggestedMessages: suggestedMessages
          //   .split("\n")
          //   .filter((message) => message.trim() !== ""),
        }
      );
      console.log("Chat interface settings saved:", response.data);
      toast.success("Chat interface settings saved");
      setChatbotKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Error saving chat interface settings:", error);
      toast.error("Error saving chat interface settings");
    }
  };

  return (
    <div className="chat-interface-container">
      <div className="settings-container">
        <h4>Chat Interface</h4>
        <FormGroup>
          <Label for="theme">Theme</Label>
          <Input
            type="select"
            id="theme"
            value={theme}
            onChange={handleThemeChange}
          >
            <option value="not selected" disabled>
              Select a theme
            </option>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="displayName">Display Name</Label>
          <Input
            type="text"
            id="displayName"
            value={displayName}
            onChange={handleDisplayNameChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="initialMessage">Initial Message</Label>
          <Input
            type="textarea"
            id="initialMessage"
            value={initialMessage}
            onChange={handleInitialMessageChange}
          />
        </FormGroup>
        {/* <FormGroup>
          <Label for="suggestedMessages">Suggested Messages</Label>
          <Input
            type="textarea"
            id="suggestedMessages"
            value={suggestedMessages}
            onChange={handleSuggestedMessagesChange}
            placeholder="Enter suggested messages, one per line"
            rows={5}
          />
        </FormGroup> */}
        <div className="watermark-section">
          {isWatermarkLocked && (
            <div className="locked-overlay">
              <Button
                className="upgrade-button"
                onClick={() => navigate("/pricing")}
              >
                <FontAwesomeIcon icon={faLock} /> Upgrade to <b>Pro</b> to
                Remove the Watermark
              </Button>
            </div>
          )}
          <FormGroup>
            <Label for="watermarkEnabled">
              Display "Powered by <b>TheWhiteLabelChatbot</b>"
            </Label>
            <br />
            <ReactSwitch
              checked={watermarkEnabled}
              onChange={handleWatermarkChange}
              disabled={isWatermarkLocked}
            />
          </FormGroup>
        </div>

        <Button color="primary" onClick={saveChanges} disabled={!isSaveEnabled}>
          <FontAwesomeIcon icon={faSave} /> Save
        </Button>
        <ToastContainer />
      </div>
      <div className="chatbot-preview-container">
        <ChatBot key={chatbotKey} />
      </div>
    </div>
  );
};

export default ChatInterfaceSubTab;
