import React, { useState, useEffect } from "react";
import { useUser } from "../../../UserContext";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const ChatbotLimitModal = ({ isOpen, toggle, onSave }) => {
  const { userMetadata, endpoint, plans } = useUser();
  const [selectedChatbots, setSelectedChatbots] = useState([]);
  const [chatbots, setChatbots] = useState([]);
  const [newPlan, setNewPlan] = useState(null);

  useEffect(() => {
    const fetchChatbots = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/users/${userMetadata.id}/chatbots`
        );
        setChatbots(response.data);
        const activeChatbots = response.data.filter(
          (chatbot) => chatbot.active
        );
        const currentPlan = plans.find(
          (plan) => plan.product_id === userMetadata.product_id
        );
        setNewPlan(currentPlan);
        console.log({ activeChatbots, currentPlan });
        setSelectedChatbots(
          activeChatbots
            .slice(0, currentPlan.chatbots)
            .map((chatbot) => chatbot.id)
        );

        if (activeChatbots.length > currentPlan.chatbots && !isOpen) {
          toggle();
        }
      } catch (error) {
        console.error("Error fetching chatbots:", error);
        toast.error("Error fetching chatbots.");
      }
    };

    if (userMetadata) {
      fetchChatbots();
    }
  }, [userMetadata, endpoint, plans, isOpen, toggle]);

  const handleChatbotSelection = (chatbotId) => {
    setSelectedChatbots((prevSelected) =>
      prevSelected.includes(chatbotId)
        ? prevSelected.filter((id) => id !== chatbotId)
        : [...prevSelected, chatbotId]
    );
  };

  const handleSubmitModal = async () => {
    try {
      for (const chatbot of chatbots) {
        await axios.put(`${endpoint}/api/chatbots/${chatbot.id}/active`, {
          active: selectedChatbots.includes(chatbot.id),
        });
      }
      toast.success("Chatbots updated successfully.");
      onSave();
    } catch (error) {
      console.error("Error updating chatbot status:", error);
      toast.error("Error updating chatbot status.");
    } finally {
      toggle();
    }
  };

  return (
    <>
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Manage Chatbot Status</ModalHeader>
      <ModalBody>
        <p>
          Select the chatbots you want to keep active (up to{" "}
          {newPlan?.chatbots}):
        </p>
        {chatbots &&
          chatbots.map((chatbot) => (
            <FormGroup check key={chatbot.id}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={selectedChatbots.includes(chatbot.id)}
                  onChange={() => handleChatbotSelection(chatbot.id)}
                  disabled={
                    !selectedChatbots.includes(chatbot.id) &&
                    selectedChatbots.length >= newPlan?.chatbots
                  }
                />
                {chatbot.name}
              </Label>
            </FormGroup>
          ))}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmitModal}>
          Save Changes
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
    <ToastContainer />
    </>
  );
};

export default ChatbotLimitModal;