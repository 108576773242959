import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const LeadForm = ({ leadSettings, onSubmit, onCancel }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const leadData = {
        title: leadSettings.title,
    };
    formData.forEach((value, key) => {
      leadData[key] = value;
    });
    onSubmit(leadData);
  };

  return (
    <div className="bot-message lead-form">
      <div className="message-content">
        {/*Cancel button */}
        <button className="cancel-button" onClick={() => onCancel({})}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <form onSubmit={handleSubmit}>
          <div className="lead-form-title">{leadSettings.title}</div>
          {leadSettings.name && (
            <FormGroup>
              <Label for="name">Name</Label>
              <Input type="text" name="name" id="name" placeholder="John Doe" required />
            </FormGroup>
          )}
          {leadSettings.email && (
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" name="email" id="email" placeholder="johndoe@example.com" required />
            </FormGroup>
          )}
          {leadSettings.phone && (
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input type="tel" name="phone" id="phone" placeholder="xxx-xxx-xxxx" required />
            </FormGroup>
          )}
          <Input type="submit" value="Submit" className="submit-button" />
        </form>
      </div>
    </div>
  );
};

export default LeadForm;
