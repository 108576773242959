import React, { useState, useEffect } from "react";
import { useUser } from "../../UserContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcVisa, faCcMastercard, faCcAmex, faCcDiscover } from "@fortawesome/free-brands-svg-icons";

const RetrieveCard = () => {
  const [card, setCard] = useState(null);
  const { endpoint, userMetadata } = useUser();

  useEffect(() => {
    const fetchCard = async () => {
      try {
        const customerId = userMetadata.stripe_customer_id;
        const response = await axios.post(`${endpoint}/stripe/retrieve-card`, {
          customerId,
        });
        if (response.data) {
          console.log('Retrieved Card Data', response.data);
          setCard(response.data);
        } else {
          console.log("No card on file");
        }
      } catch (error) {
        console.error("Error fetching card:", error);
      }
    };

    fetchCard();
  }, []);

  const getCardIcon = (brand) => {
    switch (brand) {
      case "Visa":
        return faCcVisa;
      case "MasterCard":
        return faCcMastercard;
      case "American Express":
        return faCcAmex;
      case "Discover":
        return faCcDiscover;
      default:
        return null;
    }
  };

  return (
    <div>
      {card ? (
        <div style={{ display: "flex", alignItems: "center", padding: "5px", background: "#f2f2f2", borderRadius: "8px", fontSize: "14px" }}>
          <FontAwesomeIcon icon={getCardIcon(card.brand)} size="1x" style={{ marginRight: "5px" }} />
          <div>
            <span style={{ fontWeight: "bold" }}>{card.brand}</span> <span style={{ background: "#e0e0e0", padding: "2px 5px", borderRadius: "4px", marginLeft: "5px" }}>*{card.last4}</span><span> Exp: {card.exp_month}/{card.exp_year}</span>
          </div>
        </div>
      ) : (
        <p style ={{
            color: "grey",
            fontSize: "14px",
        }}>No default card on file</p>
      )}
    </div>
  );
};

export default RetrieveCard;
