import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../../../../UserContext";
import { useParams, useNavigate } from "react-router-dom";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { Button } from "reactstrap";
import "./DashboardTab.css";
import "./AnalyticsSubTab.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const AnalyticsSubTab = () => {
  const { endpoint, userMetadata, plans } = useUser();
  const [isLocked, setIsLocked] = useState(true);
  const { id: chatbotId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let proPlanProductId = plans[2].product_id;
    console.log("proPlanProductId", proPlanProductId);
    console.log("userMetadata.product_id", userMetadata.product_id);
    setIsLocked(userMetadata.product_id !== proPlanProductId);
  }, [userMetadata]);

  const today = new Date();
  const pastSevenDays = new Date(today);
  pastSevenDays.setDate(pastSevenDays.getDate() - 7);
  const formatDate = (date) => date.toISOString().split("T")[0];
  const [leadsData, setLeadsData] = useState([]);
  const [chatLogs, setChatLogs] = useState([]);
  const [startDate, setStartDate] = useState(formatDate(pastSevenDays));
  const [endDate, setEndDate] = useState(formatDate(today));

  useEffect(() => {
    const fetchLeadsData = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/leads/chatbot/${chatbotId}`
        );
        const aggregatedData = aggregateLeadsByDate(response.data);
        setLeadsData(aggregatedData);
      } catch (err) {
        console.error("Error fetching leads data:", err);
      }
    };

    const fetchChatData = async () => {
      if (!chatbotId) {
        console.error("Chatbot ID is not available");
        return;
      }

      try {
        const response = await axios.get(
          `${endpoint}/api/assistants/${chatbotId}/conversations`
        );
        console.log("Chat logs:", response.data);
        // aggregrate chat logs by date
        const dataByDate = {};
        response.data.forEach((log) => {
          const date = log.timestamp.split("T")[0];
          if (!dataByDate[date]) {
            dataByDate[date] = { timestamp: date, message_count: 0 };
          }
          dataByDate[date].message_count += 1;
        });
        setChatLogs(Object.values(dataByDate));
      } catch (error) {
        console.error("Error fetching chat logs:", error);
      }
    };

    fetchLeadsData();
    fetchChatData();
  }, [chatbotId, endpoint]);

  // Aggregate leads by date
  const aggregateLeadsByDate = (leads) => {
    const dataByDate = {};

    leads.forEach((lead) => {
      const date = lead.created_at.split("T")[0];
      if (!dataByDate[date]) {
        dataByDate[date] = { date, leadsGenerated: 0 };
      }
      dataByDate[date].leadsGenerated += 1;
    });

    return Object.values(dataByDate);
  };

  // Filter data based on date range
  const filteredData = leadsData.filter((data) => {
    const dataDate = new Date(data.date);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return dataDate >= start && dataDate <= end;
  });

  const filteredChatLogs = chatLogs.filter((log) => {
    const logDate = new Date(log.timestamp);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return logDate >= start && logDate <= end;
  });

  return (
    <div className="analytics-subtab-container">
      {isLocked && (
        <div className="locked-overlay">
          <Button
            className="upgrade-button"
            onClick={() => navigate("/pricing")}
          >
            <FontAwesomeIcon icon={faLock} /> Upgrade Account to Access
          </Button>
        </div>
      )}
      <div className={isLocked ? "content-blur" : ""}>
        <h4>Leads Analytics</h4>
        <div>
          <label>Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={filteredData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis domain={[0, 20]} />
            <Tooltip />
            <Bar
              dataKey="leadsGenerated"
              fill="#017BFE"
              name="Leads Generated"
            />
          </BarChart>
        </ResponsiveContainer>
        <h4>Chat Logs</h4>
        <div>
          {chatLogs.length === 0 && <p>No chat logs found.</p>}
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={filteredChatLogs}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="timestamp" />
              <YAxis domain={[0, 20]} />
              <Tooltip />
              <Bar
                dataKey="message_count"
                fill="#017BFE"
                name="Chats Created"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsSubTab;
