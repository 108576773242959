import React, { useState, useEffect } from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  NavbarToggler,
  Collapse,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import "../../App.css"; // Custom CSS
import "bootstrap/dist/css/bootstrap.min.css";
import Pricing from "../Pricing/Pricing.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TheWhiteLabelLogo from "../../assets/thewhitelabelchatbot-logo-new-white.png";
import "./Main.css";
import ChatbotList from "../ChatbotComponents/ChatbotList/ChatbotList.js";
import CreateChatbot from "../ChatbotComponents/CreateChatbot/CreateChatbot";
import ChatbotManagement from "../ChatbotComponents/ChatbotManagement/ChatbotManagement";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { useUser } from "../../UserContext";
import {
  faCircle,
  faList,
  faStar,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Account from "../Account/Account";
import ChatbotLimitModal from "../ChatbotComponents/ChatbotLimitModal/ChatbotLimitModal";
import Modal from "reactstrap/lib/Modal";

function Main() {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const navigate = useNavigate();
  const { userMetadata, setUserMetadata, plans } = useUser();
  const { logout } = useAuth0();
  console.log({ userMetadata });
  const isAdmin = true;
  const [tab, setTab] = useState("chatbot-list");

  return (
    <>
      <Container
        fluid
        style={{
          backgroundColor: "#F8F9FA",
        }}
      >
        <Row
          style={{
            background: "#ECEFF3",
          }}
        >
          <Col md="3" className="d-md-none">
            <NavbarToggler
              onClick={toggleNavbar}
              className="mr-2"
              style={{
                backgroundColor: "",
                border: "none",
                color: "#0c6dfd",
                padding: 24,
                fontSize: 30,
                paddingBottom: 0,
              }}
            >
              <FontAwesomeIcon icon={faList} />
            </NavbarToggler>
            <NavbarBrand href="/">
              <img
                src={TheWhiteLabelLogo}
                alt="Igotchu Logo"
                className="left-sidebar-brand"
              />
            </NavbarBrand>
            <Collapse isOpen={!collapsed} navbar>
              <Nav vertical>
                <NavbarBrand href="/">
                  <img
                    src={TheWhiteLabelLogo}
                    alt="Igotchu Logo"
                    className="left-sidebar-brand"
                  />
                </NavbarBrand>
                <br />
                <NavItem>
                  <Link
                    to="/chatbot-list"
                    href="#"
                    onClick={() => setTab("chatbot-list")}
                    style={{
                      color:
                        tab === "chatbot-list" || tab === "create-chatbot"
                          ? "#0C6DFD"
                          : "black",
                      fontWeight:
                        tab === "chatbot-list" || tab === "create-chatbot"
                          ? "bold"
                          : "normal",
                    }}
                  >
                    <FontAwesomeIcon icon={faList} /> Chatbots
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </Col>

          {/* Permanent sidebar for larger screens */}
          <Col md="3" className="d-none d-md-block sidebar">
            <Nav vertical>
              <NavbarBrand href="/" className="left-sidebar-brand">
                <img
                  src={TheWhiteLabelLogo}
                  alt="Igotchu Logo"
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                />
              </NavbarBrand>
              <br />
              {isAdmin && (
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => {
                      setTab("chatbot-list");
                      navigate("/chatbot-list");
                    }}
                    style={{
                      color:
                        tab === "chatbot-list" || tab === "create-chatbot"
                          ? "#6666ff"
                          : "6666ff",
                      fontWeight:
                        tab === "chatbot-list" || tab === "create-chatbot"
                          ? "bold"
                          : "normal",
                      background: tab === "chatbot-list" ? "#f2f2f2" : "",
                    }}
                  >
                    <FontAwesomeIcon icon={faList} /> Chatbots
                  </NavLink>
                </NavItem>
              )}
              {isAdmin && (
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => {
                      setTab("account");
                      navigate("/account");
                    }}
                    style={{
                      color: tab === "account" ? "#6666ff" : "#6666ff",
                      fontWeight: tab === "account" ? "bold" : "normal",
                      background: tab === "account" ? "#f2f2f2" : "",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faUser} /> Account
                  </NavLink>
                </NavItem>
              )}

              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  textAlign: "center",
                  color: "gray",
                  fontSize: 12,
                  padding: 12,
                }}
              >
                <hr />
                <p>
                  Welcome back <b>{userMetadata?.username}</b>!
                </p>
                <p>
                  You are on the{" "}
                  <b>
                    {
                      plans?.find(
                        (plan) => plan.product_id === userMetadata?.product_id
                      )?.name
                    }
                  </b>{" "}
                  plan.
                </p>
                <Link to="/pricing">
                  <FontAwesomeIcon icon={faStar} /> Upgrade Account
                </Link>
                <br />
                <Button
                  color="link"
                  style={{
                    color: "gray",
                    fontSize: 12,
                    padding: 12,
                  }}
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Log out
                </Button>
              </div>
            </Nav>
          </Col>

          {/* Main Content */}
          <Col md="9" className="main-content">
            <Routes>
              <Route
                path="/"
                element={
                  <ChatbotList
                    onCreateChatbot={() => {
                      setTab("create-chatbot");
                    }}
                    onChatbotClicked={(id) => {
                      console.log("Chatbot clicked", id);
                      setTab("manage-chatbot");
                    }}
                    chatbots={[
                      {
                        id: 1,
                        name: "Test Chatbot",
                        description: "This chatbot is a test chatbot",
                      },
                      {
                        id: 2,
                        name: "Test Chatbot 2",
                        description: "This chatbot is a test chatbot",
                      },
                    ]}
                  />
                }
              />
              <Route
                path="/chatbot-list"
                element={
                  <ChatbotList
                    onCreateChatbot={() => {
                      setTab("create-chatbot");
                    }}
                    onChatbotClicked={(id) => {
                      console.log("Chatbot clicked", id);
                      setTab("manage-chatbot");
                    }}
                    chatbots={[
                      {
                        id: 1,
                        name: "Test Chatbot",
                        description: "This chatbot is a test chatbot",
                      },
                      {
                        id: 2,
                        name: "Test Chatbot 2",
                        description: "This chatbot is a test chatbot",
                      },
                    ]}
                  />
                }
              />
              <Route path="/account" element={<Account />} />
              <Route path="/pricing" element={<Pricing />} exact={false} />
              <Route path="/create-chatbot" element={<CreateChatbot />} />
              <Route
                path="/manage-chatbot/:id"
                element={<ChatbotManagement />}
              />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Main;
