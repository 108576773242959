import React, { useState, useEffect } from "react";
import axios from "axios";
const { useUser } = require("../../UserContext");

const PreviewInvoiceCard = ({ priceId }) => {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { userMetadata, endpoint } = useUser();

  const handlePreview = async () => {
    setLoading(true);
    setError(null);
    let subscriptionId = userMetadata.subscription_id;
    try {
      const response = await axios.post(
        `${endpoint}/stripe/preview-subscription-invoice`,
        {
          subscriptionId,
          priceId,
        }
      );
      console.log("Preview Invoice Response", response.data);
      setPreview(response.data);
    } catch (err) {
      setError("Failed to fetch upgrade preview");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (priceId) {
      handlePreview();
    }
  }, [priceId]);

  return (
    <div>
      {preview && (
        <div>
          <p>
            Next billing date:{" "}
            {new Date(preview.next_payment_attempt * 1000).toLocaleDateString()}
          </p>
          <p>
            <b>
              Total amount due now: {preview.amount_due / 100}{" "}
              {preview.currency.toUpperCase()}{" "}
            </b>
          </p>
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default PreviewInvoiceCard;
