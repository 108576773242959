import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Main from "./components/Main/Main.js";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import TheWhiteLabelLogo from "./assets/logo-black.png";

import TheWhiteLabelLogo from "./assets/thewhitelabelchatbot-logo-new-white.png";

import {
  Navbar,
  NavbarBrand,
  Button,
  Col,
  Row,
  NavbarText,
  Container,
} from "reactstrap";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import { UserProvider } from "./UserContext";
import ChatBot from "./components/ChatBot/ChatBot.js";
import AccordionSection from "./components/Landing/AccordianSection.js";
import Footer from "./components/Landing/Footer.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleGeminiEffect } from "./components/ui/google-gemini-effect.js";
import {
  faArrowRight,
  faDatabase,
  faDesktop,
  faFaceAngry,
  faFaceLaugh,
  faHamburger,
  faHandPaper,
} from "@fortawesome/free-solid-svg-icons";
import HeroSection from "./components/Landing/HeroSection.js";
import CardSection from "./components/Landing/CardSection.js";
import FeatureSection from "./components/Landing/FeatureSection.js";
import FormCapture from "./components/Landing/FormCapture.js";
import ChatbotToggle from "./components/ChatBot/ChatbotToggle.js";
const endpoints = {
  development: "http://localhost:5001",
  production: "https://thewhitelabelchatbot-server-d661a4729595.herokuapp.com",
};


// production plan ids
const productionPlans = [
  {
    product_id: "prod_PiKe6qMQ6cRLNb",
    price_id: "price_1OstzYJl6jO4J2vHJap2pInz",
    name: "Free",
    chatbots: 1,
    character_limit: 40000,
    price: 0,
  },
  {
    product_id: "prod_PiKe73yM2opm4I",
    price_id: "price_1OstzUJl6jO4J2vHEFy2Ln0S",
    name: "Hobby",
    chatbots: 2,
    character_limit: 1000000,
    price: 19.99,
  },
  {
    product_id: "prod_PiKeOacAsWl2bj",
    price_id: "price_1OstzXJl6jO4J2vH45NDcieS",
    name: "Pro",
    chatbots: 5,
    character_limit: 1000000,
    price: 99.99,
  },
];

// development plan ids
const developmentPlans = [
  {
    product_id: "prod_Pd4JBz0HzH88jK",
    price_id: "price_1OnoBGJl6jO4J2vHiCi7U1fm",
    name: "Free",
    chatbots: 1,
    character_limit: 40000,
    price: 0,
  },
  {
    product_id: "prod_Pc6i2nMVrMBbHn",
    price_id: "price_1OmsUoJl6jO4J2vHPBlhPX44",
    name: "Hobby",
    chatbots: 2,
    character_limit: 1000000,
    price: 19.99,
  },
  {
    product_id: "prod_Pch4HxUUdhRJWe",
    price_id: "price_1OnRgnJl6jO4J2vHSCbvFxto",
    name: "Pro",
    chatbots: 5,
    character_limit: 1000000,
    price: 99.99,
  },
];

const allPlans = {
  development: developmentPlans,
  production: productionPlans,
};
const endpoint = endpoints.production;
const plans = allPlans.production;

function App() {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const createUser = async () => {
    if (isAuthenticated) {
      const userData = {
        auth0Id: user.sub,
        username: user.nickname,
        email: user.email,
      };

      try {
        // Check if the user exists
        const checkResponse = await axios.get(
          `${endpoint}/api/users/${userData.auth0Id}`
        );
        if (checkResponse.data.exists) {
          console.log("User already exists:", userData.auth0Id);
          setUserMetadata(checkResponse.data.user);
        } else {
          // Create the user if they don't exist
          const createResponse = await axios.post(
            `${endpoint}/api/users`,
            userData
          );
          console.log("User created:", createResponse.data);
          setUserMetadata(createResponse.data);
        }
      } catch (error) {
        console.error("Error handling user:", error);
      }
    }
  };

  useEffect(() => {
    createUser();
  }, [isAuthenticated]);

  return (
    <UserProvider value={{ userMetadata, setUserMetadata, endpoint, plans }}>
      <Router>
        <div className="App">
          <Routes>
            <Route
              path="/chatbot/:id"
              element={
                <div
                  style={{
                    width: "100vw",
                    height: "100vh",
                  }}
                >
                  <ChatBot />
                </div>
              }
            />
            {isAuthenticated ? (
              <>
                <Route path="/*" element={<Main />} />
                {/* Add other protected routes here */}
              </>
            ) : (
              <Route
                path="/"
                element={
                  <>
                    <Navbar
                      expand="md"
                      style={{
                        // background: "white",
                        background:
                          "linear-gradient(to left, #33ccff 0%, #6666ff 100%)",
                        borderBottom: "3px solid white",
                        width: "80%",
                        margin: "auto",
                        borderBottomRightRadius: 20,
                        borderBottomLeftRadius: 20,
                        height: 80,
                      }}
                    >
                      <NavbarBrand
                        href="/"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          paddingLeft: 20,
                          marginTop: 15,
                        }}
                      >
                        <img
                          src={TheWhiteLabelLogo}
                          style={{
                            width: 200,
                            height: 76,
                            marginRight: 10,
                            borderRadius: 50,
                            padding: 0,
                          }}
                          alt="igotchu-logo"
                        />
                      </NavbarBrand>
                      <NavbarText>
                        <Button
                          onClick={() => loginWithRedirect()}
                          className="login-button"
                        >
                          Login
                        </Button>
                      </NavbarText>
                    </Navbar>
                    <HeroSection
                      loginWithRedirect={() => {
                        loginWithRedirect();
                      }}
                    ></HeroSection>
                    <AccordionSection />
                    <CardSection></CardSection>
                    <FeatureSection></FeatureSection>
                    <FormCapture></FormCapture>
                    <Footer></Footer>
                  </>
                }
              />
            )}
            {/* <Route path="*" element={<Navigate replace to="/" />} /> */}
          </Routes>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
