import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCog,
  faRobot,
  faMessage,
  faStar,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import "./ChatbotList.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../../UserContext";
import ChatbotLimitModal from "../ChatbotLimitModal/ChatbotLimitModal";
import { toast } from "react-toastify";
const ChatbotList = () => {
  const [chatbots, setChatbots] = useState([]);
  const [inactiveChatbots, setInactiveChatbots] = useState([]);
  const { userMetadata, endpoint, plans } = useUser();
  const [totalChatbotsAllowed, setTotalChatbotsAllowed] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleSave = () => {
    // Refresh the chatbot list after saving changes in the modal
    fetchChatbots();
  };

  const fetchChatbots = async () => {
    if (userMetadata) {
      try {
        if (!userMetadata.id || !userMetadata.product_id) {
          console.log("User data not found", userMetadata);
          return;
        }
        const response = await axios.get(
          `${endpoint}/api/users/${userMetadata.id}/chatbots`
        );
        const chatbotsResponse = response.data;
        const activeChatbots = chatbotsResponse.filter(
          (chatbot) => chatbot.active
        );
        const inactiveChatbots = chatbotsResponse.filter(
          (chatbot) => !chatbot.active
        );
        setInactiveChatbots(inactiveChatbots);
        setChatbots(activeChatbots);
      } catch (error) {
        console.error("Error fetching chatbots:", error);
      }
    }
  };

  useEffect(() => {
    try {
      if (!userMetadata || !userMetadata.product_id) {
        console.log("User metadata product_id not found", userMetadata);
        return;
      }
      switch (userMetadata.product_id) {
        case plans[0].product_id:
          setTotalChatbotsAllowed(plans[0].chatbots);
          break;
        case plans[1].product_id:
          setTotalChatbotsAllowed(plans[1].chatbots);
          break;
        case plans[2].product_id:
          setTotalChatbotsAllowed(plans[2].chatbots);
          break;
        default:
          setTotalChatbotsAllowed(1);
          break;
      }
    } catch (error) {
      console.error("Error fetching chatbots:", error);
    }

    fetchChatbots();
  }, [userMetadata]);

  return (
    <div className="dashboard">
      <Row>
        <span>
          /{" "}
          <Link to="/chatbot-list" className="directory-display">
            Chatbots
          </Link>{" "}
        </span>
      </Row>
      <Row className="justify-content-end">
        <Col md="2" className="text-right">
          {chatbots.length >= totalChatbotsAllowed ? (
            <Link
              to="/pricing"
              className="create-chatbot-btn"
              style={{
                backgroundColor: "gold",
                color: "black",
              }}
            >
              <FontAwesomeIcon icon={faLock} /> Upgrade Plan to Create More
              Chatbots
            </Link>
          ) : (
            <Link to="/create-chatbot" className="create-chatbot-btn">
              <FontAwesomeIcon icon={faPlus} /> Create New Chatbot
            </Link>
          )}
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
      <Row>
        <Col>
          <div className="page-header">
            Chatbots{" "}
            <span
              style={{
                fontSize: ".8rem",
                color: "grey",
              }}
            >
              ({chatbots?.length} / {totalChatbotsAllowed})
            </span>
          </div>
          <div
            className="activate"
            style={{
              color: "blue",
              cursor: "pointer",
            }}
            onClick={toggleModal}
          >
            Manage active chatbots
          </div>{" "}
          <br />
          <div className="chatbot-list">
            {chatbots.length > 0 ? (
              chatbots.map((chatbot, index) => (
                <Card key={index} className="chatbot-card">
                  <Link
                    to={`/manage-chatbot/${chatbot.id}`}
                    className="chatbot-link"
                  >
                    <CardBody
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="chatbot-thumbnail"
                        style={{
                          minWidth: "120px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMessage}
                          style={{ fontSize: "3rem", margin: "auto" }}
                        />
                      </div>
                      <div className="chatbot-details">
                        <CardTitle tag="h5">{chatbot.name}</CardTitle>
                        <CardText>{chatbot.description}</CardText>
                      </div>
                    </CardBody>
                  </Link>
                </Card>
              ))
            ) : (
              <p className="no-chatbots">No active chatbots created yet.</p>
            )}
          </div>
          {inactiveChatbots.length > 0 && (
            <>
            <br />

              <div>Inactive Chatbots</div>
              <p
                style={{
                  color: "grey",
                  fontSize: ".8rem",
                }}
              >
                Upgrade your plan to activate more chatbots. Inactive chatbots
                are not accessible to users.{" "}
              </p>
            </>
          )}
          <br />
          <div className="chatbot-list">
            {inactiveChatbots.length > 0
              ? inactiveChatbots.map((chatbot, index) => (
                  <Card key={index} className="chatbot-card">
                    <div
                      className="chatbot-link"
                      style={{
                        cursor: "not-allowed",
                        opacity: "0.5",
                      }}
                    >
                      <CardBody
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="chatbot-thumbnail"
                          style={{
                            minWidth: "120px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMessage}
                            style={{ fontSize: "3rem", margin: "auto" }}
                          />
                        </div>
                        <div className="chatbot-details">
                          <CardTitle tag="h5">{chatbot.name}</CardTitle>
                          <CardText>{chatbot.description}</CardText>
                        </div>
                      </CardBody>
                    </div>
                  </Card>
                ))
              : ""}
          </div>
        </Col>
      </Row>
      <ChatbotLimitModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
      />
    </div>
  );
};

export default ChatbotList;
