// LeadsSubTab.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../../../../UserContext";
import { useParams } from "react-router-dom";
import { Table, Input, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./LeadsSubTab.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "./DashboardTab.css";

const LeadsSubTab = () => {
  const [leads, setLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { endpoint, userMetadata, plans } = useUser();
  const { id: chatbotId } = useParams();
  const [isLocked, setIsLocked] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let proPlanProductId = plans[0].product_id;
    console.log("proPlanProductId", proPlanProductId);
    console.log("userMetadata.product_id", userMetadata.product_id);
    setIsLocked(userMetadata.product_id === proPlanProductId);
  }, [userMetadata]);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/leads/chatbot/${chatbotId}`
        );
        setLeads(response.data);
      } catch (err) {
        console.error("Error fetching leads:", err);
      }
    };

    fetchLeads();
  }, [chatbotId, endpoint]);

  const formatDate = (date) => {
    if (!date) return null;
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    return `${month}/${day}/${year} @ ${hours % 12 || 12}:${minutes} ${ampm}`;
  };

  const filterLeads = () => {
    return leads.filter((lead) => {
      const leadDate = new Date(lead.created_at);
      return (
        (searchTerm === "" ||
          Object.values(lead).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )) &&
        (!startDate || leadDate >= startDate) &&
        (!endDate || leadDate <= endDate)
      );
    });
  };

  return (
    <div className="dashboard-leads-subtab-container">
      {isLocked && (
        <div className="locked-overlay">
          <Button className="upgrade-button" onClick={() => navigate("/pricing")}>
            <FontAwesomeIcon icon={faLock} /> Upgrade Account to Access
          </Button>
        </div>
      )}
      <div className={isLocked ? "content-blur" : ""}>
        <h4>Leads</h4>
        <FormGroup>
          <Label for="search">Search</Label>
          <Input
            type="text"
            id="search"
            placeholder="Search leads"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Date Range</Label>
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              isClearable
              placeholderText="Start date"
            />
            {" - "}
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              isClearable
              placeholderText="End date"
            />
          </div>
        </FormGroup>
        <Table striped>
          <thead>
            <tr>
              <th>Title</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            {filterLeads().length === 0 && (
              <tr>
                <td colSpan="5">No leads found</td>
              </tr>
            )}
            {filterLeads().map((lead, index) => (
              <tr key={index}>
                <td>{lead.title}</td>
                <td>{lead.name}</td>
                <td>{lead.email}</td>
                <td>{lead.phone}</td>
                <td>{formatDate(lead.created_at)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default LeadsSubTab;
