import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Progress,
} from "reactstrap";
import "./CreateChatbot.css";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../../UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faBrush,
  faCode,
  faComment,
  faComments,
  faHeadset,
  faInfoCircle,
  faMagicWandSparkles,
  faMarker,
  faPerson,
  faRobot,
  faRocket,
  faScissors,
  faShoppingCart,
  faSuitcase,
  faToolbox,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";

import {
  faBots,
  faDiscord,
  faSalesforce,
} from "@fortawesome/free-brands-svg-icons";
const steps = [
  { icon: faCode, subtitle: "Building the bot" },
  { icon: faRobot, subtitle: "Constructing the bot" },
  { icon: faMagicWandSparkles, subtitle: "Training the bot" },
  { icon: faComments, subtitle: "Testing the bot" },
  { icon: faScissors, subtitle: "Finalizing the bot" },
  { icon: faBrush, subtitle: "Polishing the bot" },
  { icon: faRocket, subtitle: "Deploying the bot" },
  // Add more steps as needed
];
const LoadingAnimation = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const [fade, setFade] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
        setFade(false);
      }, 1000); // Change the step in the middle of the fade-out
    }, 3000); // Change every 3 seconds (to allow for fade-in and fade-out)

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`loading-animation ${fade ? "fade" : ""}`}>
      <FontAwesomeIcon icon={steps[currentStep].icon} size="3x" />
      <p>{steps[currentStep].subtitle}</p>
    </div>
  );
};

const CreateChatbot = () => {
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("A chatbot assistant");
  const [model, setModel] = useState("gpt-4-turbo-preview");
  const [useCase, setUseCase] = useState("");
  const [temperature, setTemperature] = useState(0.7);
  const [isRewriting, setIsRewriting] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRedirect, setRedirect] = useState(false);
  const [isGeneratingInstructions, setIsGeneratingInstructions] =
    useState(false);
  const { userMetadata, endpoint } = useUser();

  const useCases = [
    { title: "Customer Support", icon: faHeadset },
    { title: "FAQ Bot", icon: faInfoCircle },
    { title: "E-commerce Assistant", icon: faShoppingCart },
    { title: "Coding Assistant", icon: faCode },
    { title: "General Chatbot", icon: faComments },
    { title: "Discord Bot", icon: faDiscord },
    { title: "Sales Representative", icon: faSuitcase },
    { title: "Custom Use Case", icon: faRobot },
  ];

  const generateInstructions = async () => {
    setIsGeneratingInstructions(true);
    try {
      const response = await axios.post(
        `${endpoint}/api/generate/instructions`,
        {
          metadata: {
            name: name,
            useCase: useCase,
            contentType: "instructions",
          },
        }
      );
      console.log("Instructions generated:", response.data);
      setInstructions(response.data);
      setIsGeneratingInstructions(false);
      toast.success("Instructions generating Successful");
    } catch (error) {
      console.error("Error updating instructions:", error);
      setIsGeneratingInstructions(false);
      toast.error("Error generating instructions");
    }
  };

  const requestRewrite = async () => {
    setIsRewriting(true);
    try {
      const response = await axios.post(
        `${endpoint}/api/rewrite/instructions`,
        {
          content: instructions,
          contentType: "instructions",
        }
      );
      console.log("Instructions updated:", response.data);
      setInstructions(response.data);
      setIsRewriting(false);
      toast.success("Instructions rewrite Successful");
    } catch (error) {
      console.error("Error updating instructions:", error);
      setIsRewriting(false);
      toast.error("Error rewriting instructions");
    }
  };

  const handleInstructionsChange = (event) => {
    setInstructions(event.target.value);
  };

  const createChatbot = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("model", model);
      formData.append("temperature", temperature);
      formData.append("instructions", instructions);
      formData.append("userId", userMetadata.id);
      formData.append("useCase", useCase);

      const response = await axios.post(`${endpoint}/api/chatbots`, formData);
      console.log("Chatbot created:", response.data);
      setRedirect(true);
      toast.success("Chatbot created");
    } catch (error) {
      console.error("Error creating chatbot:", error);
      if (error?.response?.data) {
        toast.error(error.response.data);
      } else {
        toast.error("Error creating chatbot");
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (shouldRedirect) {
    return <Navigate to="/chatbot-list" />;
  }

  return (
    <div className="create-chatbot">
      <Row className="mb-4">
        <Col>
          <Link to="/chatbot-list" className="back-link">
            Back to Chatbots
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className="page-header"
            style={{
              textAlign: "center",
              color: "#6666FF",
            }}
          >
            Create New Chatbot
          </div>
          <br />
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mx-auto">
          <Card>
            {!isLoading && (
              <div>
                <Progress value={step * 16} />
              </div>
            )}

            <CardBody>
              {step === 0 && (
                <FormGroup>
                  <h5>Choose Your Use Case</h5>
                  <p style={{ color: "grey" }}>
                    Select the intended use case for your chatbot.
                  </p>
                  <div className="use-case-grid">
                    {useCases.map((caseItem, index) => (
                      <Button
                        key={index}
                        className={`use-case-button ${
                          useCase === caseItem.title ? "selected" : ""
                        }`}
                        onClick={() => setUseCase(caseItem.title)}
                      >
                        <FontAwesomeIcon icon={caseItem.icon} size="2x" />
                        <div className="use-case-title">{caseItem.title}</div>
                      </Button>
                    ))}
                  </div>

                  <Button
                    color="primary"
                    disabled={!useCase}
                    onClick={() => setStep(1)}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#6666FF",
                    }}
                  >
                    Next
                  </Button>
                </FormGroup>
              )}
              {step === 1 && (
                <FormGroup>
                  <Label for="nameInput">
                    <h5>Chatbot Name</h5>
                  </Label>
                  <p style={{ color: "grey" }}>
                    Pick a name for your chatbot assistant.
                  </p>
                  <Input
                    type="text"
                    id="nameInput"
                    value={name}
                    placeholder="Customer Assistant"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Button
                    color="primary"
                    onClick={() => setStep(0)}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#6666FF",
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </Button>{" "}
                  <Button
                    color="primary"
                    onClick={() => setStep(5)}
                    disabled={!name}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#6666FF",
                    }}
                  >
                    Next <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </FormGroup>
              )}
              {/* {step === 2 && (
                <FormGroup>
                  <Label for="descriptionInput">
                    <h5>Description</h5>
                  </Label>
                  <p style={{ color: "grey" }}>
                    This is a brief description of what the chatbot does and how
                    it helps the user.
                  </p>
                  <Input
                    type="textarea"
                    id="descriptionInput"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="This chatbot helps customers with their questions about our products/services."
                  />
                  <Button
                    color="primary"
                    onClick={() => setStep(1)}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#6666FF",
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </Button>{" "}
                  <Button
                    color="primary"
                    onClick={() => setStep(3)}
                    disabled={!description}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#6666FF",
                    }}
                  >
                    Next <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </FormGroup>
              )}
              {step === 3 && (
                <FormGroup>
                  <Label for="modelInput">
                    <h5>Model</h5>
                  </Label>
                  <p style={{ color: "grey" }}>
                    Choose the model that will be used to generate responses.
                  </p>
                  <Input
                    type="select"
                    id="modelInput"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                  >
                    <option value="gpt-4-turbo-preview">gpt-4-turbo</option>
                    <option value="gpt-3.5-turbo" disabled>
                      gpt-3.5-turbo
                    </option>
                  </Input>
                  <Button
                    color="primary"
                    onClick={() => setStep(2)}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#6666FF",
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </Button>{" "}
                  <Button
                    color="primary"
                    disabled={!model}
                    onClick={() => setStep(4)}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#6666FF",
                    }}
                  >
                    Next <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </FormGroup>
              )}
              {step === 4 && (
                <FormGroup>
                  <Label for="temperatureInput">
                    <h5>Temperature</h5>
                  </Label>
                  <p style={{ color: "grey" }}>
                    Choose the temperature that will be used to generate
                    responses.
                  </p>
                  <Input
                    type="number"
                    id="temperatureInput"
                    value={temperature}
                    disabled
                    onChange={(e) => setTemperature(parseFloat(e.target.value))}
                    min="0"
                    max="1"
                    step="0.1"
                  />
                  <Button
                    color="primary"
                    onClick={() => setStep(3)}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#6666FF",
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </Button>{" "}
                  <Button
                    color="primary"
                    onClick={() => setStep(5)}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#6666FF",
                    }}
                  >
                    Next <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </FormGroup>
              )} */}
              {step === 5 && (
                <FormGroup>
                  {isLoading && (
                    <div className="loading-container">
                      <LoadingAnimation />
                    </div>
                  )}
                  {!isLoading && (
                    <>
                      <Label for="instructionsInput">
                        <h5>Instructions</h5>
                      </Label>
                      <p style={{ color: "grey" }}>
                        Provide instructions for the chatbot to follow.
                      </p>
                      <Input
                        type="textarea"
                        id="instructionsInput"
                        value={instructions}
                        onChange={(e) => setInstructions(e.target.value)}
                        placeholder="You are an assistant helping a customer with a problem. You need to be polite and helpful."
                      />
                      {instructions && (
                        <>
                          <Button
                            style={{
                              marginTop: "10px",
                              background: "#3333CC",
                              border: "none",
                            }}
                            disabled={
                              !instructions ||
                              isRewriting ||
                              isGeneratingInstructions
                            }
                            onClick={() => {
                              requestRewrite();
                              // setInstructions("Rewriting with A.I");
                              // setIsSaveEnabled(true);
                            }}
                          >
                            {isRewriting && (
                              <FontAwesomeIcon
                                icon={faWandMagicSparkles}
                                spin
                              />
                            )}
                            {!isRewriting && (
                              <>
                                <FontAwesomeIcon icon={faMagicWandSparkles} />{" "}
                                Rewrite for me{" "}
                              </>
                            )}
                          </Button>{" "}
                        </>
                      )}
                      <Button
                        style={{
                          marginTop: "10px",
                          background: "#3333CC",
                          border: "none",
                        }}
                        disabled={isRewriting || isGeneratingInstructions}
                        onClick={() => {
                          generateInstructions();
                          // setInstructions("Rewriting with A.I");
                          // setIsSaveEnabled(true);
                        }}
                      >
                        {isGeneratingInstructions && (
                          <FontAwesomeIcon icon={faWandMagicSparkles} spin />
                        )}
                        {!isGeneratingInstructions && (
                          <>
                            <FontAwesomeIcon icon={faMagicWandSparkles} />{" "}
                            Generate instructions for me{" "}
                          </>
                        )}
                      </Button>
                    </>
                  )}
                  <br />
                  <Button
                    color="primary"
                    onClick={() => setStep(1)}
                    disabled={isLoading}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#6666FF",
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </Button>{" "}
                  {!instructions ||
                    !name ||
                    !description ||
                    !model ||
                    (!temperature && (
                      <div style={{ color: "red", marginTop: "20px" }}>
                        Please fill out all fields (name, description, model,
                        temperature, instructions)
                      </div>
                    ))}
                  <Button
                    color="primary"
                    // disabled={
                    //   isLoading ||
                    //   !instructions ||
                    //   !name ||
                    //   !description ||
                    //   !model ||
                    //   !temperature
                    // }
                    disabled={!name || !instructions || isLoading}
                    style={{
                      marginTop: "20px",
                      border: "none",
                      backgroundColor: "#3333CC",
                    }}
                    onClick={createChatbot}
                  >
                    {isLoading ? "Creating..." : "Create Chatbot"}
                  </Button>
                </FormGroup>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default CreateChatbot;
