import React, { useState, useEffect } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";
import { useUser } from "../../../../UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ChatBot from "../../../ChatBot/ChatBot";

const GeneralSubTab = () => {
  const [name, setName] = useState("");
  const [chatbotData, setChatbotData] = useState({});
  const [description, setDescription] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const { id: chatbotId } = useParams();
  const { endpoint } = useUser();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const fetchChatbotData = async () => {
    try {
      const response = await axios.get(`${endpoint}/api/chatbots/${chatbotId}`);
      setChatbotData(response.data);
      setName(response.data.name);
      setDescription(response.data.description);
      console.log(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchChatbotData();
  }, [chatbotId]);

  const handleSave = async () => {
    try {
      await axios.put(
        `${endpoint}/api/chatbots/${chatbotData.id}/name-description`,
        {
          name,
          description,
        }
      );
      fetchChatbotData();
      setIsEditing(false);
      toast.success("Chatbot name updated");
    } catch (error) {
      toast.error("Error updating chatbot name");
      console.error("Error updating chatbot name:", error);
    }
  };

  return (
    <div className="chat-interface-container">
      <div className="settings-container">
        <h4>General</h4>
        <div className="setting-item">
          <strong>Name:</strong>
          {isEditing ? (
            <Input type="text" value={name} onChange={handleNameChange} />
          ) : (
            ` ${chatbotData.name}`
          )}
        </div>
        <br />
        <div className="setting-item">
          <strong>Description:</strong>
          {isEditing ? (
            <Input
              type="textarea"
              value={description}
              onChange={handleDescriptionChange}
            />
          ) : (
            ` ${chatbotData.description}`
          )}
        </div>
        <br />
        <div className="setting-item">
          <strong>Chatbot ID:</strong> {chatbotData.id}
        </div>
        <br />
        <div className="setting-item">
          <strong>Status:</strong>{" "}
          <span style={{ color: "green" }}>
            {chatbotData.active ? "Active" : "Inactive"}
          </span>
        </div>
        <br />
        {isEditing ? (
          <>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>{" "}
            <Button
              onClick={() => {
                setIsEditing(false);
                setName(chatbotData.name);
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            style={{ background: "#6666FF", color: "white" }}
            onClick={handleEdit}
          >
            Edit
          </Button>
        )}
      </div>
      <div className="chatbot-preview-container">
        <ChatBot />
      </div>
      <ToastContainer />
    </div>
  );
};

export default GeneralSubTab;
